import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import styles from "./unarchiveCofrimationModal.module.css";

// for Form
import { TextField, InputAdornment, Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import axios from "axios";

// API URL
// API endpoints
import { vccConfig } from "widgets/config";
// import { veoraconfig } from "widgets/config";

import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import BeatLoader from "react-spinners/BeatLoader";

const UnarchiveConfirmationModal = (props) => {
  const [editNetsuiteHostUrlFormData, setEditNetsuiteHostUrlFormData] =
    useState({
      netsuite_url: "",
      erp_app_id: "",
      erp_app_secret: "",
    });
  const [loginFormData, setLoginFormData] = useState({
    userName: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerificationCodeLoading, setIsVerificationCodeLoading] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    setEditNetsuiteHostUrlFormData({
      netsuite_url: "",
    });
    setLoginFormData({
      userName: "",
      password: "",
    });
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(false);
  }, [props.show]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setEditNetsuiteHostUrlFormData({
      ...editNetsuiteHostUrlFormData,
      [name]: value,
    });
  };
  const handleLoginFormChange = (e) => {
    const { name, value } = e.target;
    setLoginFormData({
      ...loginFormData,
      [name]: value,
    });
  };

  const updateNetsuiteEndpoint = async () => {
    // e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(true);
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
    try {
      const response = await axios({
        method: "POST",
        url:
          vccConfig.vccAPI.baseURL +
          vccConfig.vccAPI.settings.updateNetsuiteEndPoint,
        headers: {
          Authorization: `Bearer ${authData.data.token}`,
          "Content-Type": "application/json",
        },
        data: {
          netsuite_url: editNetsuiteHostUrlFormData.netsuite_url,
        },
      });
      console.log("email sent code api res", response);
      const res = response.data;
      if (res.success === true) {
        setErrorMsg("");
        setSuccessMsg(res.data.message);
        props.setFetchAgainFlag((prev) => prev + 1);
      } else {
        setSuccessMsg("");
        setErrorMsg(
          "Could not Update Netsuite Endpoint. Please try again later!"
        );
      }
    } catch (error) {
      console.log("error in catch", error);
      setSuccessMsg("");

      setErrorMsg(
        "Could not Update Netsuite Endpoint. Please try again later!"
      );
    }
    setIsSubmitting(false);
  };

  const handleFormDataSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(true);
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
    const vccUsername = localStorage.getItem("vccUsername");
    try {
      const res = await fetch(
        // "https://veora-dev-server.azurewebsites.net/api/v1/auth/login",
        vccConfig.vccAPI.baseURL + vccConfig.vccAPI.auth.login,
        // "https://ql83c6bf3c.execute-api.ca-central-1.amazonaws.com/api/v1/user/login",
        {
          method: "POST",
          body: JSON.stringify({
            email: vccUsername,
            password: loginFormData.password,
          }),
          headers: {
            "Content-type": "application/json",
            // "mode": "no-cors",
            // "Access-Control-Allow-Origin": "http://localhost:3000/",
            // origin: "http://localhost:3000/",
          },
        }
      );
      const data = await res.json();
      if (data.success === true) {
        localStorage.setItem("vccAuthInfo", JSON.stringify(data));
        localStorage.setItem("vccUsername", vccUsername);
        updateNetsuiteEndpoint();
        setErrorMsg("");
        // setSuccessMsg(res.data.message);
      } else {
        setSuccessMsg("");
        setErrorMsg("Password is invalid!");
      }
    } catch (error) {
      console.log("error in catch", error);
      setSuccessMsg("");

      setErrorMsg(
        "Could not Update Netsuite Endpoint. Please try again later!"
      );
    }
    setIsSubmitting(false);
  };
  const handleUnarchiveTransaction = () => {
    props.handleUnarchiveTransaction(props.selectedTransactionIDForUnarchive);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      //   className={styles["addCleanerModal"]}
      //   size="sm"
      //   dialogClassName="modal-90w"
      dialogClassName={styles["deleteUserModal"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
          Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles["deleteUserModal-body"]}>
        {props.selectedTransactionIDForUnarchive === null ? (
          <>null</>
        ) : (
          <>
            Are you sure to unarchive transaction no:{" "}
            <span style={{ fontWeight: "bold" }}>
              {props.selectedTransactionIDForUnarchive[0].value} ?
            </span>
          </>
        )}

        {/* <FormControl
          className={styles["userPassEditModal-control"]}
          component="form"
          // onSubmit={handleFormDataSubmit}
          fullWidth
        >
          {successMsg.emailCodeSuccessMsg && (
            <Typography variant="h7" color="green" align="center">
              {successMsg.emailCodeSuccessMsg}
            </Typography>
          )}
          {errorMsg.emailCodeErrorMsg && (
            <Typography variant="h7" color="red" align="center">
              {errorMsg.emailCodeErrorMsg}
            </Typography>
          )}
          <TextField
            fullWidth
            // type="password"
            required
            label="Netsuite Host URL"
            id="netsuite_url"
            name="netsuite_url"
            value={editNetsuiteHostUrlFormData.netsuite_url}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            required
            label="ERP App ID"
            id="erp_app_id"
            name="erp_app_id"
            value={editNetsuiteHostUrlFormData.erp_app_id}
            onChange={handleFormDataChange}
          />
           <TextField
            fullWidth
            // type="password"
            required
            label="ERP App Secret"
            id="erp_app_secret"
            name="erp_app_secret"
            value={editNetsuiteHostUrlFormData.erp_app_secret}
            onChange={handleFormDataChange}
          />
         
          <TextField
            fullWidth
            type={showPassword ? "text" : "password"}
            required
            label="Password"
            id="password"
            name="password"
            value={loginFormData.password}
            onChange={handleLoginFormChange}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

         
          {errorMsg && (
            <Typography variant="h7" color="red">
              {errorMsg}
            </Typography>
          )}
          {successMsg && (
            <Typography variant="h7" color="green">
              {successMsg}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            style={{ background: "#2E4082", color: "white",borderRadius:"8px" }}
          >
            {isSubmitting === true ? "Please Wait" : "Submit"}
          </Button>
        </FormControl> */}
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "25px",
          justifyContent: "center",
          color: "black",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          // disabled={isSubmitting}
          onClick={() => handleUnarchiveTransaction()}
          style={{
            background: "rgba(0, 128, 0, 0.749)",
            color: "white",
            borderRadius: "8px",
          }}
          // style={{ background: "#2E4082", color: "white", borderRadius: "8px" }}
        >
          YES
        </Button>
        <Button
          variant="contained"
          fullWidth
          // disabled={isSubmitting}
          onClick={() => props.onHide()}
          style={{
            background: "rgba(255, 0, 0, 0.782)",
            color: "white",
            borderRadius: "8px",
          }}
        >
          Discard
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnarchiveConfirmationModal;
