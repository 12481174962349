/*
-------------------------------------
 Omor's Sign-up system for Veora v1.0
 ------------------------------------
*/

// react-router-dom components
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// react-loader-spinner
import { ThreeDots } from "react-loader-spinner";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import BasicLayout from "layouts/authentication/components/BasicLayout";

// VerifyToken function to verify the private route/api
import verifyToken from "../verifyToken";

// Images
import bgImage from "assets/images/bg-sign-in-veora.jpg";
function Cover() {
  const [username, setUsername] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [error, setError] = useState("");
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isRegisterLoading, setIsRegisterLoading] = useState(false);

  let navigate = useNavigate();

  const isVarified = async () => {
    if (localStorage.getItem("authInfo")) {
      // handleVerifyToken(localStorage.getItem("authToken"));
      const authData = JSON.parse(localStorage.getItem("authInfo"));
      console.log(authData.token);
      const res = await verifyToken(authData.token);
      if (res === true) {
        console.log("verified");
        navigate("/dashboard");
      } else {
        console.log("not yet verified");
        setIsLoading(false);
      }
    } else {
      console.log("should be navigated to the login page as no token exists");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isVarified();
    // console.log(first)
  }, []);

  const registerHandler = async (e) => {
    e.preventDefault();

    console.log(username, email, password);
    try {
      setIsRegisterLoading(true);
      const res = await fetch(
        "https://bright-ox-shirt.cyclic.app/user/signup",
        {
          method: "POST",
          body: JSON.stringify({
            username: username,
            email: email,
            password: password,
          }),
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const data = await res.json();
      console.log(data);
      if (res.status === 201 && data.success === true) {
        console.log("success signup");
        setError(false);
        setIsSignupSuccess(true);
        setIsRegisterLoading(false);
      } else if (
        res.status === 400 &&
        data.success === false &&
        data.errorType === 1
      ) {
        console.log("signup failed with type 1");
        setError(true);
        setIsSignupSuccess(false);
        setErrorMessage(data.errorMessage);
        setIsRegisterLoading(false);
      } else {
        console.log("signup failed with type 2");
        setError(true);
        setIsSignupSuccess(false);
        setErrorMessage("Something went wrong. Please try again!");
        setIsRegisterLoading(false);
      }
      // if (res.status === 200 && data.success === true) {
      //   setError(false);
      //   localStorage.setItem("authInfo", JSON.stringify(data));
      //   navigate("/dashboard");
      //   console.log(data);
      // } else {
      //   console.log("could not login");
      //   setError(true);
      // }
    } catch (err) {
      setError(true);
      setIsSignupSuccess(false);
      setErrorMessage("Something went wrong. Please try again!");
      setIsRegisterLoading(false);
    }
  };

  return (
    <>
      {isLoading === false ? (
        <>
          {/* <CoverLayout image={bgImage}> */}
          <BasicLayout image={bgImage}>
            <Card>
              <MDBox
                variant="gradient"
                //bgColor="veoracolor2"
                style={{ background: "#317de0" }}
                borderRadius="lg"
                coloredShadow="success"
                mx={2}
                mt={-3}
                p={3}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  VCC Registration
                </MDTypography>
                <MDTypography
                  display="block"
                  variant="button"
                  color="white"
                  fontWeight="light"
                  my={1}
                >
                  Enter required information to register
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" onSubmit={registerHandler} role="form">
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      required
                      label="Name"
                      variant="standard"
                      fullWidth
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      required
                      label="Email"
                      variant="standard"
                      fullWidth
                      value={email}
                      onChange={(e) => setemail(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      required
                      label="Password"
                      variant="standard"
                      fullWidth
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                    />
                  </MDBox>
                  <MDBox display="flex" alignItems="center" ml={-1}>
                    <Checkbox required />
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                    >
                      &nbsp;&nbsp;I agree with the&nbsp;
                    </MDTypography>
                    <MDTypography
                      component="a"
                      href="#"
                      variant="button"
                      fontWeight="light"
                      style={{ color: "#317de0" }}
                      
                    >
                      Terms and Conditions
                    </MDTypography>
                  </MDBox>
                  <MDBox mt={4} mb={1} textAlign="center">
                    {error === false && isSignupSuccess === true ? (
                      <>
                        <MDTypography
                          textAlign="center"
                          color="success"
                          fontWeight="light"
                          textGradient
                          variant="h6"
                        >
                          Registration success. <br />
                          Please login!
                        </MDTypography>
                      </>
                    ) : null}
                    {error === true && isSignupSuccess === false ? (
                      <>
                        <MDTypography
                          textAlign="center"
                          color="error"
                          fontWeight="light"
                          textGradient
                          variant="h6"
                        >
                          Sorry! <br />
                          {errorMessage}.
                        </MDTypography>
                      </>
                    ) : null}
                  </MDBox>
                  <MDBox mt={4} mb={1}>
                    {isRegisterLoading === false ? (
                      <>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="veoracolor2"
                          fontWeight="light"
                          style={{ background: "#317de0" }}
                          fullWidth
                        >
                          sign up
                        </MDButton>
                      </>
                    ) : (
                      <>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="veoracolor2"
                          fontWeight="light"
                          fullWidth
                          disabled
                        >
                          <ThreeDots
                            height="25"
                            width="25"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </MDButton>
                      </>
                    )}
                  </MDBox>
                  <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography
                      variant="button"
                      fontWeight="light"
                      color="text"
                    >
                      Already have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/login"
                        variant="button"
                        color="#317de0"
                        fontWeight="light"

                        style={{ color: "#317de0" }}
                      >
                        Log In
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        </>
      ) : null}
    </>
  );
}

export default Cover;