import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import styles from "./createSupportTicketModal.module.css";

// for Form
import { TextField, InputAdornment, Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

// API URL
import axios from "axios";
import { vccConfig } from "widgets/config";

import BeatLoader from "react-spinners/BeatLoader";

const CreateSupportTicketModal = (props) => {
  const [createSupportFormData, setCreateSupportFormData] = useState({
    username: "",
    email: "",
    transaction_id: "",
    message: "",
    // status: "NEW",
    merchant_id: "",
    priority: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerificationCodeLoading, setIsVerificationCodeLoading] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  useEffect(() => {
    setCreateSupportFormData({
      username: "",
      email: "",
      transaction_id: "",
      message: "",
      // status: "NEW",
      merchant_id: "",
      priority: "",
    });
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(false);
  }, [props.show]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setCreateSupportFormData({
      ...createSupportFormData,
      [name]: value,
    });
  };
  const handleFormDataSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");

    setIsSubmitting(true);
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
    const vccUsername = localStorage.getItem("vccUsername");
    try {
      const response = await axios({
        method: "POST",
        url:
          vccConfig.vccAPI.baseURL +
          vccConfig.vccAPI.supportTicket.supportTicket,
        headers: {
          Authorization: `Bearer ${authData.data.token}`,
          "Content-Type": "application/json",
        },
        data: {
          username: vccUsername,
          email: createSupportFormData.email,
          transaction_id: createSupportFormData.transaction_id,
          message: createSupportFormData.message,
          status: "NEW",
          merchant_id: `${authData.data.userData.merchant_id}`,
          priority: createSupportFormData.priority,
        },
      });
      console.log("create support api res", response);
      const res = response.data;
      if (res.success === true) {
        setErrorMsg("");
        setSuccessMsg(res.message);
      } else {
        setSuccessMsg("");
        setErrorMsg("Could not create support ticket. Please try again later!");
      }
    } catch (error) {
      console.log("error in catch", error);
      setSuccessMsg("");

      setErrorMsg("Could not create support ticket. Please try again later!");
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      {...props}
      //   className={styles["addCleanerModal"]}
      //   size="sm"
      //   dialogClassName="modal-90w"
      dialogClassName={styles["deleteUserModal"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
          Create Support Ticket
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles["deleteUserModal-body"]}>
        <FormControl
          className={styles["userPassEditModal-control"]}
          component="form"
          onSubmit={handleFormDataSubmit}
          fullWidth
        >
          {successMsg.emailCodeSuccessMsg && (
            <Typography variant="h7" color="green" align="center">
              {successMsg.emailCodeSuccessMsg}
            </Typography>
          )}
          {errorMsg.emailCodeErrorMsg && (
            <Typography variant="h7" color="red" align="center">
              {errorMsg.emailCodeErrorMsg}
            </Typography>
          )}
          <TextField
            fullWidth
            // type="password"
            required
            label="Email"
            id="email"
            name="email"
            value={createSupportFormData.email}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            // required
            label="Transaction ID (Optional)"
            id="transaction_id"
            name="transaction_id"
            value={createSupportFormData.transaction_id}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            required
            label="Message"
            id="message"
            name="message"
            value={createSupportFormData.message}
            onChange={handleFormDataChange}
          />
          {/* <TextField
            fullWidth
            // type="password"
            required
            label="Merchant ID"
            id="merchant_id"
            hidden
            name="merchant_id"
            value={createSupportFormData.merchant_id}
            onChange={handleFormDataChange}
          /> */}
          {/* <TextField
            fullWidth
            // type="password"
            required
            label="Priority"
            id="priority"
            name="priority"
            value={createSupportFormData.priority}
            onChange={handleFormDataChange}
          /> */}
          <FormControl fullWidth>
            <InputLabel id="priority-label">Priority *</InputLabel>
            <Select
              labelId="priority"
              size="normal"
              label="Priority *"
              required
              id="priority"
              name="priority"
              value={createSupportFormData.priority}
              onChange={handleFormDataChange}
              style={{ height: "2.8rem" }}
            >
              <MenuItem value="Low">Low</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="High">High</MenuItem>
            </Select>
          </FormControl>

          {/* <FormControlLabel
            required
            control={<Checkbox />}
            label="I have read and agree to the terms"
          /> */}
          {errorMsg && (
            <Typography variant="h7" color="red">
              {errorMsg}
            </Typography>
          )}
          {successMsg && (
            <Typography variant="h7" color="green">
              {successMsg}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            style={{ color: "white" }}
          >
            {isSubmitting === true ? "Please Wait" : "Submit"}
          </Button>
        </FormControl>
      </Modal.Body>
      <Modal.Footer
        style={{ display: "flex", justifyContent: "center", color: "black" }}
      ></Modal.Footer>
    </Modal>
  );
};

export default CreateSupportTicketModal;
