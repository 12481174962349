/**
=========================================================
========== Omor's coding for login system v1.0
=========================================================
*/

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// react-router-dom components
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// react-loader-spinner
import { ThreeDots } from "react-loader-spinner";
import prsLogo from "../../../../src/assets/vccAssets/prs_logo1.png";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/vcc1.jpg";

// API URL
import { vccConfig } from "widgets/config";

// VerifyToken function to verify the private route/api
import verifyToken from "../verifyToken";

// for hashing
import bcrypt from "bcryptjs";

// forget password Modal
import ForgetPasswordModal from "../components/ForgetPasswordModal/ForgetPasswordModal";

import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const salt = bcrypt.genSaltSync(10);

function Basic({ history }) {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSigninLoading, setIsSigninLoading] = useState(false);
  const [forgetPasswordModalShow, setForgetPasswrodModalShow] = useState(false);
  const location = useLocation();
  const { state } = location;

  //Success Message Snackbar on Password Reset
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // password show starts
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // password show ends

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  let navigate = useNavigate();

  const isVarified = async () => {
    if (localStorage.getItem("authInfo")) {
      // handleVerifyToken(localStorage.getItem("authToken"));
      const authData = JSON.parse(localStorage.getItem("authInfo"));
      console.log(authData.accesstoken);
      const res = await verifyToken(authData.accesstoken);
      if (res.success === true) {
        console.log("verified");
        navigate("/admin-panel");
      } else {
        console.log("not yet verified");
        setIsLoading(false);
      }
    } else {
      console.log("should be navigated to the login page as no token exists");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state && state.successMsg) {
      // Use the success message as needed
      openSnackbarWithMessage(state.successMsg);
    }
  }, [state]);

  const openSnackbarWithMessage = (message) => {
    setSnackbarMessage(message);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const SnackbarMessage = () => (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={openSnackbar}
      autoHideDuration={6000} // Adjust the duration as needed
      onClose={handleCloseSnackbar}
    >
      <SnackbarContent
        style={{
          backgroundColor: "#4CAF50", // Set the background color as needed
        }}
        message={snackbarMessage}
      />
    </Snackbar>
  );

  const loginHandler = async (e) => {

    e.preventDefault();
    const hashedPassword = bcrypt.hashSync(password, salt);

    console.log(email, password);
    try {
      setIsSigninLoading(true);
      const res = await fetch(
        // "https://veora-dev-server.azurewebsites.net/api/v1/auth/login",
        vccConfig.vccAPI.baseURL + vccConfig.vccAPI.auth.login,
        // "https://ql83c6bf3c.execute-api.ca-central-1.amazonaws.com/api/v1/user/login",
        {
          method: "POST",
          body: JSON.stringify({
            email: email,
            password: password,
            // password: hashedPassword,
          }),
          headers: {
            "Content-type": "application/json",
            // "mode": "no-cors",
            // "Access-Control-Allow-Origin": "http://localhost:3000/",
            // origin: "http://localhost:3000/",
          },
        }
      );
      const data = await res.json();
      console.log(data);
      if (res.status === 200 && data.success === true) {
        setError(false);
        localStorage.setItem("vccAuthInfo", JSON.stringify(data));
        localStorage.setItem("vccUsername", email);
        if (data.data.userType === "User") {
          navigate("/dashboard");
        } else if (data.data.userType === "Admin") {
          navigate("/admin-panel");
        }
        setIsSigninLoading(false);

        console.log(data);
      } else {
        console.log("could not login");
        setError(true);
        setIsSigninLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError(true);
      setIsSigninLoading(false);
    }
  };

  const handleForgetPassword = () => {
    // console.log("ok");
    setForgetPasswrodModalShow(true);
  };

  return (
    <>
      <ForgetPasswordModal
        show={forgetPasswordModalShow}
        onHide={() => setForgetPasswrodModalShow(false)}
      />

      {isLoading === false ? (
        <>
          <BasicLayout image={bgImage}>
            <Card>
              {/* <MDBox
                style={{ background: "#2E4082" }}
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  PRS Login
                </MDTypography>
                
              </MDBox> */}
              <MDBox
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-6}
                // p={2}
                mb={1}
                textAlign="center"
                component="img"
                src={prsLogo}
                alt="PRS"
                //width="20rem"
                height="7rem"
                //sx={{transform: "scale(1)"}}
                sx={{ mb: "2%" }}
                style={{ backgroundColor: "white" }}
              />
              <MDBox pt={6} pb={3} px={3}>
                <MDBox component="form" onSubmit={loginHandler} role="form">
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      required
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      // type="password"
                      type={showPassword ? "text" : "password"}
                      label="Password"
                      required
                      fullWidth
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MDBox>
                  <MDBox
                    display="flex"
                    alignItems="center"
                    ml={-1}
                    // justifyContent="space-between"
                    justifyContent="end"
                  >
                    {/* <div>
                      <Switch
                        checked={rememberMe}
                        onChange={handleSetRememberMe}
                      />
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        onClick={handleSetRememberMe}
                        sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                      >
                        &nbsp;&nbsp;Remember me
                      </MDTypography>
                    </div> */}
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleForgetPassword}
                      sx={{ cursor: "pointer" }}
                    >
                      &nbsp;&nbsp;Forgot password?
                    </MDTypography>
                  </MDBox>
                  {isError === true ? (
                    <>
                      <MDBox mt={2} mb={1} textAlign="center">
                        <MDTypography
                          color="error"
                          fontWeight="light"
                          // verticalAlign="middle"
                          variant="h6"
                        >
                          Invalid Username or password. <br /> Please Try Again!
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : null}

                  <MDBox mt={3} mb={1}>
                    {isSigninLoading === false ? (
                      <>
                        <MDButton
                          type="submit"
                          color="veoracolor2"
                          fontWeight="medium"
                          fullWidth
                          style={{
                            background: "#2E4082",
                            color: "white",
                            fontSize: "16px",
                          }}
                        >
                          Login
                        </MDButton>
                      </>
                    ) : (
                      <>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          //color="veoracolor2"
                          style={{ background: "#2E4082" }}
                          fullWidth
                          disabled
                        >
                          <ThreeDots
                            height="25"
                            width="25"
                            radius="9"
                            color="veoracolor2"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </MDButton>
                      </>
                    )}
                  </MDBox>
                  {/* <MDBox mt={3} mb={1} textAlign="center">
                    <MDTypography variant="button" color="text">
                      Don&apos;t have an account?{" "}
                      <MDTypography
                        component={Link}
                        to="/register"
                        variant="button"
                        color="veoracolor2"
                        fontWeight="medium"
                      >
                        Sign up
                      </MDTypography>
                    </MDTypography>
                  </MDBox> */}
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
          <SnackbarMessage />
        </>
      ) : null}
    </>
  );
}

export default Basic;
