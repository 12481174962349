/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
*/

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Automation from "layouts/device/components/Automation";

function Automations() {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Latest&apos;s Automations
        </MDTypography>
        <MDBox display="flex" alignItems="flex-start">
          <MDBox color="text" mr={0.5} lineHeight={0}>
            <Icon color="inherit" fontSize="small">
              date_range
            </Icon>
          </MDBox>
          <MDTypography variant="button" color="text" fontWeight="regular">
            22 - 21 September 2022
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox pt={3} pb={2} px={2}>
        <MDBox mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            newest
          </MDTypography>
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <Automation
            color="warning"
            icon="expand_more"
            name="HVAC Temp Regulation"
            description="22 September 2022, at 12:30 PM"
            value="Manual Override"
          />
          <Automation
            color="success"
            icon="expand_less"
            name="HVAC Temp Regulation"
            description="22 September 2022, at 04:30 AM"
            value="Executed"
          />
        </MDBox>
        <MDBox mt={1} mb={2}>
          <MDTypography variant="caption" color="text" fontWeight="bold" textTransform="uppercase">
            yesterday
          </MDTypography>
        </MDBox>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <Automation
            color="success"
            icon="expand_less"
            name="HVAC Power-off"
            description="21 September 2022, at 20:45"
            value="Executed"
          />
          <Automation
            color="success"
            icon="expand_less"
            name="HVAC Temp Regulation"
            description="21 September 2022, at 18:30"
            value="Executed"
          />
          <Automation
            color="success"
            icon="expand_less"
            name="HVAC Temp Regulation"
            description="21 September 2022, at 14:30"
            value="Executed"
          />
          <Automation
            color="dark"
            icon="priority_high"
            name="HVAC Temp Regulation"
            description="21 September 2022, at 12:30"
            value="Within levels"
          />
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Automations;
