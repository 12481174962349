/*
-----------------------------------
Omor's code for token verification
-----------------------------------
*/
// API URL
import { veoraconfig } from "widgets/config";

const verifyToken = async (accessToken) => {
  console.log("verify token fun er vitor:", accessToken);
  // return true
  try {
    const res = await fetch(
      // "https://veora-dev-server.azurewebsites.net/api/v1/auth/tokenverify",
      veoraconfig.veoraapi.url + veoraconfig.auth.tokenVerify,
      {
        method: "POST",
        // body: JSON.stringify({
        //   token: accessToken,
        // }),
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data);
    return data
  } catch (err) {
    return false;
  }
};

export default verifyToken;
