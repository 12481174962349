import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDProgress from "components/MDProgress";
import Icon from "@mui/material/Icon";

//ticket table
import UsersTable from "./components/UsersTable/UsersTable";
import UserlistTable from "./components/UserlistTable/UserlistTable";
// Ticket create Modal

import UserCreateModal from "./components/UserCreateModal/UserCreateModal";
const ContactUs = () => {
  const [openUserCreateModal, setOpenUserCreateModal] = useState(false);
  const [fetchAgainFlag , setFetchAgainFlag] = useState(0)

  return (
    <div>
      <UserCreateModal
        show={openUserCreateModal}
        onHide={() => setOpenUserCreateModal(false)}
        setFetchAgainFlag={setFetchAgainFlag}
      />
      <DashboardLayout>
        <DashboardNavbar />
        {/* This is the Contact Page */}
        <MDBox
          mx={2}
          //mt={5}
          py={3}
          px={2}
          //variant="gradient"
          bgColor="#6B7CA8"
          borderRadius="lg"
          coloredShadow="info"
          style={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            width: "100%",
          }}
        >
          {/* <MDTypography variant="h6" color="white">
            Cleaners Working
          </MDTypography> */}
          {/* <MDButton variant="gradient" color="veoracolor2" fontWeight="light" onClick={handlemodal}> */}
          <MDButton
            //variant="gradient"
            color="veoracolor2"
            fontWeight="light"
            onClick={() => setOpenUserCreateModal(true)}
            style={{ width: "30%", backgroundColor:"#2E4082" }}
          >
            <Icon sx={{ fontWeight: "light" }}>add</Icon>
            &nbsp;add new User
          </MDButton>
        </MDBox>
        {/* <UsersTable /> */}
        <UserlistTable renderFlag={fetchAgainFlag} />
      </DashboardLayout>
    </div>
  );
};

export default ContactUs;
