import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDProgress from "components/MDProgress";
import Icon from "@mui/material/Icon";

// transaction data
import UserlistTable from "./components/UserlistTable/UserlistTable";

// Ticket create Modal

const ContactUs = () => {
  const [openUserCreateModal, setOpenUserCreateModal] = useState(false);

  return (
    <div>
      
      <DashboardLayout>
        <DashboardNavbar />
        {/* This is the Contact Page */}
        {/* <MDBox
          mx={2}
          mt={5}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          style={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
            width: "100%",
          }}
        >
          <MDButton
            variant="gradient"
            color="veoracolor2"
            fontWeight="light"
            // onClick={() => setOpenUserCreateModal(true)}
            style={{ width: "30%" }}
          >
            <Icon sx={{ fontWeight: "light" }}>add</Icon>
            &nbsp;add new User
          </MDButton>
        </MDBox> */}
        {/* <UsersTable /> */}
        <UserlistTable renderFlag={openUserCreateModal} />
      </DashboardLayout>
    </div>
  );
};

export default ContactUs;
