/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
// air monitor dashboard
// garbage monitor dashboard
// washroom odor dashboard
// superadmin dashboard
import SuperAdminDashboard from "layouts/SuperAdminDashboard/SuperAdminDashboard";
// contact us page
import Support from "layouts/Support/Support";
// First Time pass change page
// VCC Dashboard
import VCCDashboard from "layouts/VCCDashboard/VCCDashboard.jsx";
// Profile
import Profile from "layouts/profile/index";
// Reconciliation System
import ReconciliationSettings from "layouts/ReconciliationSettings/ReconciliationSettings.jsx";
// @mui icons

import SignIn from "layouts/authentication/sign-in";

import Icon from "@mui/material/Icon";

const routes = [
  {
    pageID: "etc",
    type: "collapse",
    name: "Admin Panel",
    key: "admin-panel",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    route: "/admin-panel",
    component: <SuperAdminDashboard />,
    isSubmenu: false,
  },
  {
    pageID: "etc",
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",
    component: <VCCDashboard />,
    isSubmenu: false,
  },
  {
    pageID: "etc",
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <Icon fontSize="medium">support_agent</Icon>,
    route: "/support",
    component: <Support />,
    isSubmenu: false,
  },
  // {
  //   pageID: "etc",
  //   type: "collapse",
  //   name: "First Time Pass change",
  //   key: "first-time-password-change",
  //   icon: <Icon fontSize="medium">dashboard</Icon>,
  //   route: "/first-time-password-change",
  //   component: <FirstTimePasswordChange />,
  //   isSubmenu: false,
  // },
  {
    pageID: "etc",
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="medium">badge</Icon>,
    route: "/profile",
    component: <Profile />,
    isSubmenu: false,
  },
  
  {
    pageID: "etc",
    type: "collapse",
    name: "Settings",
    key: "settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    route: "/settings",
    component: <ReconciliationSettings />,
    isSubmenu: false,
  },
  {
    pageID: "etc",
    type: "collapse",
    name: "Log Out",
    key: "logout",
    icon: <Icon fontSize="medium">logout</Icon>,
    route: "/login",
    component: <SignIn />,
    isSubmenu: false,
    // onClick: () => {
    //   // Trigger the logout logic here
    //   localStorage.removeItem("vccAuthInfo");
      
    // },
    
  },

  /*
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },*/
];

export default routes;
