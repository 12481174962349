import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { Button } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// API URL
import { vccConfig } from "widgets/config";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  Toolbar,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// leading spinners
import BeatLoader from "react-spinners/BeatLoader";
// // Edit User Modal
// import EditUserModal from '../EditUserModal/EditUserModal'

// // Delete User Modal
// import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
// // reset passwrod
// import ResetPasswordModal from "../ResetPasswordModal/ResetPasswordModal";
//import FlagIcon from "@mui/icons-material/Flag";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
// create support ticket modal
import CreateSupportTicketModal from "../CreateSupportTicketModal/CreateSupportTicketModal";
// archive confirmation modal
import ArchiveConfirmationModal from "../ArchiveConfirmationModal/ArchiveConfirmationModal";
// Unarchive confirmation modal
import UnarchiveConfirmationModal from "../UnarchiveConfirmationModal/UnarchiveConfirmationModal";
// export to csv
import { CSVLink, CSVDownload } from "react-csv";

const csvHeaders = [
  { label: "Transaction ID", key: "transaction_id" },
  { label: "Merchant ID", key: "merchant_id" },
  { label: "Order Amount", key: "order_amount" },
  { label: "Currency", key: "currency" },
  { label: "Discount Amount", key: "discount_amount" },
  { label: "Tax Total", key: "tax_total" },
  { label: "Date of Transaction Creation", key: "created_date" },
  { label: "Date of Transaction Confirmation", key: "lastmodified_date" },
  { label: "Recon Status", key: "recon_status" },
  // { label: "Job Post URL", key: "url" },
];

const UserlistTable = (props) => {
  const [userData, setUserData] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [resetPasswordUserModalOpen, setResetPasswordUserModalOpen] =
    useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [reconciliationStatus, setReconciliationStatus] = useState("all");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUserData, setSelectedUserData] = useState();
  // New state variable for toggling archived entries
  const [showArchived, setShowArchived] = useState(() => {
    const storedShowArchived = localStorage.getItem("showArchived");
    return storedShowArchived ? JSON.parse(storedShowArchived) : false;
  });
  const [archiveConfirmationModalOpen, setArchiveConfirmationModalOpen] =
    useState(false);
  const [unarchiveConfirmationModalOpen, setUnarchiveConfirmationModalOpen] =
    useState(false);
  const [selectedTransactionIDForArchive, setSelectedTransactionIDForArchive] =
    useState(null);
  const [
    selectedTransactionIDForUnarchive,
    setSelectedTransactionIDForUnarchive,
  ] = useState(null);
  const [archivedTransactions, setArchivedTransactions] = useState([]);
  // For pagination STARTS
  const [dashboardPageNo, setDashboardPageNo] = useState(1);
  const [dashboardTotalPageCount, setDashboardTotalPageCount] = useState(1);
  // For pagination ENDS
  // For search queries
  const [searchQueries, setSearchQueries] = useState("");
  const [isSearched, setIsSearched] = useState(false);

  // for pagination page change handler
  const onPageChangeHandler = (event, value) => {
    setDashboardPageNo(value);
    console.log("page no:", value);
    // fetchDashboardData();
  };

  const handleOpenArchiveModal = (id) => {
    console.log("ata id", id);
    setSelectedTransactionIDForArchive(id);
    setArchiveConfirmationModalOpen(true);
  };

  function handleArchiveTransaction(id) {
    console.log("user data to edit", id[0].value);
    console.log("user data to edit", id[0]);
    const selectedTransaction = id[0].row.original;

    // Move the selected transaction to archivedTransactions
    // const newArchivedTransactions = archivedTransactions.filter(
    //   (item) => item !== selectedTransaction
    // );
    setArchivedTransactions((prevArchivedTransactions) => {
      const updatedArchivedTransactions = [
        ...prevArchivedTransactions,
        {
          ...selectedTransaction,
          archived: true,
        },
      ];

      // Update localStorage with the new archivedTransactions
      localStorage.setItem(
        "archivedTransactions",
        JSON.stringify(updatedArchivedTransactions)
      );
      return updatedArchivedTransactions;
    });
    setSelectedUserData(id[0].value);
  }
  const handleOpenUnarchiveModal = (id) => {
    console.log("ata id", id);
    setSelectedTransactionIDForUnarchive(id);
    setUnarchiveConfirmationModalOpen(true);
  };
  function handleUnarchiveTransaction(id) {
    console.log("user data to edit", id[0].value);
    console.log("user data to edit", id[0]);
    const selectedTransaction = id[0].row.original;

    // Move the selected transaction to archivedTransactions
    setArchivedTransactions((prevArchivedTransactions) => {
      // const updatedArchivedTransactions = [
      //   ...prevArchivedTransactions,
      //   {
      //     ...selectedTransaction,
      //     archived: false,
      //   },
      // ];
      const updatedArchivedTransactions = prevArchivedTransactions.filter(
        (item) => item.transaction_id !== selectedTransaction.transaction_id
      );

      // Update localStorage with the new archivedTransactions
      localStorage.setItem(
        "archivedTransactions",
        JSON.stringify(updatedArchivedTransactions)
      );
      return updatedArchivedTransactions;
    });
    setSelectedUserData(id[0].value);
  }

  const handleShowArchivedChange = () => {
    const updatedShowArchived = !showArchived;
    setShowArchived(updatedShowArchived);

    // Store the updated showArchived value in localStorage
    localStorage.setItem("showArchived", JSON.stringify(updatedShowArchived));
  };

  const handleFilterButtonClick = () => {
    setShowFilters(!showFilters);
  };

  const handleReconciliationStatusChange = (event) => {
    setReconciliationStatus(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  var filteredData = dashboardData;
  const filterData = () => {
    // Filter out archived transactions from the fetched data
    filteredData = filteredData.filter(
      (item) =>
        !archivedTransactions.some(
          (archivedItem) => archivedItem.transaction_id === item.transaction_id
        )
    );

    if (!showArchived) {
      // Filter out archived entries
      filteredData = filteredData.filter((item) => {
        // Check if the item is in archivedTransactions and has archived: true
        return !archivedTransactions.some(
          (archivedItem) =>
            archivedItem.transaction_id === item.transaction_id &&
            archivedItem.archived
        );
      });

      // console.log(filteredData);
    } else {
      // console.log(filteredData);

      // Include archived transactions from localStorage
      const storedArchivedTransactions =
        JSON.parse(localStorage.getItem("archivedTransactions")) || [];

      // Filter out duplicates by excluding transactions already in filteredData
      const uniqueArchivedTransactions = storedArchivedTransactions.filter(
        (archivedTransaction) =>
          !filteredData.some(
            (item) => item.transaction_id === archivedTransaction.transaction_id
          )
      );

      // Merge the current filteredData with the unique archived transactions
      filteredData = [...filteredData, ...uniqueArchivedTransactions];

      // Sort the merged array based on created_date
      filteredData.sort(
        (a, b) => new Date(a.created_date) - new Date(b.created_date)
      );
    }
    if (reconciliationStatus !== "all") {
      filteredData = filteredData.filter(
        (item) => item.recon_status.toLowerCase() === reconciliationStatus
      );
    }

    if (startDate && endDate) {
      filteredData = filteredData.filter((item) => {
        const itemDate = new Date(item.created_date);
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);

        // Set time to midnight for all dates
        itemDate.setHours(0, 0, 0, 0);
        startDateObj.setHours(0, 0, 0, 0);
        endDateObj.setHours(0, 0, 0, 0);

        return itemDate >= startDateObj && itemDate <= endDateObj;
      });
    }

    return filteredData;
  };
  // function handleDeleteUser(id) {
  //   console.log("user data to delete", id);
  //   setSelectedUserData(id);
  //   setDeleteUserModalOpen(true);
  // }
  // function handleResetPassword(id) {
  //   console.log("user data to reset pass", id);
  //   setSelectedUserData(id);
  //   setResetPasswordUserModalOpen(true);
  // }
  useEffect(() => {
    // console.log('selected User data', selectedUserData[4].value)
  }, [selectedUserData]);
  const columns = [
    {
      Header: "Transaction ID",
      accessor: "transaction_id",
    },
    {
      Header: "Merchant ID",
      accessor: "merchant_id",
    },
    {
      Header: "Order Amount",
      accessor: "order_amount",
    },
    {
      Header: "Currency",
      accessor: "currency",
    },

    {
      Header: "Discount Amount",
      accessor: "discount_amount",
    },
    {
      Header: "Tax Total",
      accessor: "tax_total",
    },

    {
      Header: "Date of Transaction Creation",
      accessor: "created_date",
    },
    {
      Header: "Date of Transaction Confirmation",
      accessor: "lastmodified_date",
    },
    {
      Header: "Recon Status",
      accessor: "recon_status",
    },
  ];

  const fetchSearchedDashboardData = async () => {
    if (searchQueries === "") {
      setIsSearched(false);
      fetchDashboardData();
    } else {
      setIsSearched(true);
      console.log("search with", searchQueries);

      const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
      try {
        const response = await fetch(
          vccConfig.vccAPI.baseURL +
            vccConfig.vccAPI.dashboard.fetchSearchedDashboardData +
            `?id=${searchQueries}`,
          {
            headers: {
              Authorization: `Bearer ${authData.data.token}`,
              "Content-Type": "application/json",
            },
          }
        ); // Replace with your API endpoint
        const data = await response.json();
        console.log("searched data", data);
        if (data.success === true) {
          console.log("searched dashboard data", data.data);
          setDashboardTotalPageCount(1);

          // Load archived transactions from localStorage
          const archivedTransactions =
            JSON.parse(localStorage.getItem("archivedTransactions")) || [];

          console.log("archieved transactions", archivedTransactions);

          // Filter out archived transactions from the fetched data
          // const filteredData = data.data.transactions.filter(
          const filteredData = data.data.filter(
            (item) =>
              !archivedTransactions.some(
                (archivedItem) =>
                  archivedItem.transaction_id === item.transaction_id
              )
          );

          console.log(filteredData);
          setDashboardData(data.data);
          setArchivedTransactions(archivedTransactions);
        } else {
          console.log("success: false");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const fetchDashboardData = async () => {
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
    try {
      const response = await fetch(
        vccConfig.vccAPI.baseURL +
          vccConfig.vccAPI.dashboard.fetchDashboard +
          `?limit=10&page=${dashboardPageNo}`,
        {
          headers: {
            Authorization: `Bearer ${authData.data.token}`,
            "Content-Type": "application/json",
          },
        }
      ); // Replace with your API endpoint
      const data = await response.json();
      console.log(data);
      if (data.success === true) {
        console.log("dashboard data", data.data.transactions);
        setDashboardTotalPageCount(data.data.totalpage);

        // Load archived transactions from localStorage
        const archivedTransactions =
          JSON.parse(localStorage.getItem("archivedTransactions")) || [];

        console.log(archivedTransactions);

        // Filter out archived transactions from the fetched data
        const filteredData = data.data.transactions.filter(
          (item) =>
            !archivedTransactions.some(
              (archivedItem) =>
                archivedItem.transaction_id === item.transaction_id
            )
        );

        console.log(filteredData);
        setDashboardData(filteredData);
        setArchivedTransactions(archivedTransactions);
      } else {
        console.log("success: false");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    fetchDashboardData();
  }, [dashboardPageNo]);

  useEffect(() => {
    setUserData([]);
    // fetchUserData();
  }, [editUserModalOpen, deleteUserModalOpen, props.renderFlag]);

  // const userData = [
  //   {
  //     _id: "5631068f41a243d2bafc387483c3aed1",
  //     clientid: "a57b352d6b284ad7a00a080487cc15b0",
  //     contact: "+54622",
  //     email: "user1@gmail.com",
  //     isActive: true,
  //     isPasswordSet: false,
  //     name: "user1",
  //     pageaccess: ["dashboard", "console", "insights", "washroom"],
  //     password:
  //       "pbkdf2:sha256:260000$HtTzc156lNNWNKci$717bbe9f5c8f25216041014c0c7be8b824c0f68e0a326f7c20a7baef30b703c0",
  //     role: ["washroommanager", "buildingmanager"],
  //   },
  // ];

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Action",
        Header: "Action",
        align: "center",
        Cell: ({ row }) => (
          <>
            {!row.original.archived ? (
              <Button
                variant="contained"
                size="small"
                sx={{
                  marginLeft: "5px",
                  background: "#2E4082",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#000000",
                    borderColor: "#171946",
                    boxShadow: "none",
                  },
                }}
                onClick={() => {
                  // handleEditUser(row.cells);
                  handleOpenArchiveModal(row.cells);
                }}
              >
                <span style={{ color: "#ffffff" }}>
                  <Tooltip title="Archive">
                    <ArchiveIcon fontSize="large" color="white" />
                  </Tooltip>
                </span>
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    marginLeft: "5px",
                    background: "#D1BF7D",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#000000",
                      borderColor: "#171946",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => {
                    // handleEditUser(row.cells);
                    // handleOpenArchiveModal(row.cells);
                    handleOpenUnarchiveModal(row.cells);
                  }}
                >
                  <span style={{ color: "#ffffff" }}>
                    <Tooltip title="Unarchive">
                      {/* <ArchiveIcon size="large" color="white" /> */}
                      <UnarchiveIcon fontSize="large" color="white" />
                    </Tooltip>
                  </span>
                </Button>
                {/* <Typography variant="body2" color="textSecondary">
                  Archived
                </Typography> */}
              </>
            )}
          </>
        ),
      },
    ]);
  };

  return (
    <div>
      <ArchiveConfirmationModal
        show={archiveConfirmationModalOpen}
        onHide={() => setArchiveConfirmationModalOpen(false)}
        selectedTransactionIDForArchive={selectedTransactionIDForArchive}
        handleArchiveTransaction={handleArchiveTransaction}
        // setFetchAgainFlag={setFetchAgainFlag}
      />
      <UnarchiveConfirmationModal
        show={unarchiveConfirmationModalOpen}
        onHide={() => setUnarchiveConfirmationModalOpen(false)}
        selectedTransactionIDForUnarchive={selectedTransactionIDForUnarchive}
        handleUnarchiveTransaction={handleUnarchiveTransaction}
        // setFetchAgainFlag={setFetchAgainFlag}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Filter Button */}

        <IconButton onClick={handleFilterButtonClick}>
          <FilterListIcon />
          <Typography variant="body2" sx={{ marginLeft: "4px" }}>
            Filter
          </Typography>
        </IconButton>

        {/* Filter Controls */}
        {showFilters && (
          <div display="flex" alignItems="center" marginLeft="8px">
            <FormControl sx={{ m: 1, minWidth: 200 }}>
              <InputLabel id="reconciliation-status-label">Status</InputLabel>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                  label="Status"
                  labelId="reconciliation-status-label"
                  id="reconciliation-status"
                  value={reconciliationStatus}
                  onChange={handleReconciliationStatusChange}
                  sx={{ height: "45px", flexGrow: 1, position: "relative" }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="success">Success</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="failure">Failure</MenuItem>
                </Select>
                <IconButton
                  size="small"
                  edge="end"
                  sx={{
                    position: "absolute",
                    right: "8px",
                    padding: 0,
                    pointerEvents: "none",
                  }}
                >
                  <ArrowDropDownIcon />
                </IconButton>
              </div>
            </FormControl>
            <TextField
              id="start-date"
              label="Start Date"
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ m: 1 }}
            />
            <TextField
              id="end-date"
              label="End Date"
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ m: 1 }}
            />
          </div>
        )}
      </div>

      <CreateSupportTicketModal
        onHide={() => setEditUserModalOpen(false)}
        selectedTransactionID={selectedUserData}
        show={editUserModalOpen}
      />
      {/* <EditUserModal
        onHide={() => setEditUserModalOpen(false)}
        // clientPermittedPagesList={clientPermittedPagesList}
        selectedUserData={selectedUserData}
        clientPermittedPagesList={props.clientPermittedPagesList}
        show={editUserModalOpen}
      />
      <DeleteUserModal
        onHide={() => setDeleteUserModalOpen(false)}
        selectedUserData={selectedUserData}
        show={deleteUserModalOpen}
      />
      <ResetPasswordModal
        onHide={() => setResetPasswordUserModalOpen(false)}
        selectedUserData={selectedUserData}
        show={resetPasswordUserModalOpen}
      /> */}
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={4}
                mt={-4}
                py={3}
                px={2}
                // variant="gradient"
                bgColor="#6B7CA8"
                borderRadius="lg"
                //  coloredShadow="light"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  fontWeight="light"
                  align="center"
                >
                  All Transaction Records
                </MDTypography>
              </MDBox>

              <MDBox
              // mx={2}
              // mt={-3}
              // py={3}
              // px={2}
              // borderRadius="lg"
              // coloredShadow="veoracolor"
              >
                <FormControlLabel
                  style={{
                    marginLeft: "auto",
                    textAlign: "right",
                    marginTop: "5px",
                  }}
                  control={
                    <Checkbox
                      checked={showArchived}
                      onChange={handleShowArchivedChange}
                      color="primary"
                    />
                  }
                  label="Show Archived"
                />
                {/* Here, instead of MaterialTable, we used MUI Table  */}
                <CSVLink
                  data={filterData()}
                  filename={"VCC_Dashboard.csv"}
                  headers={csvHeaders}
                >
                  <Button
                    // disabled={selectedRowsForCSV.length === 0}
                    variant="contained"
                    style={{ color: "white" }}
                    sx={{
                      background: "#2E4082",
                      marginLeft: "1rem",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#6A7BA7",
                        borderColor: "#171946",
                        boxShadow: "none",
                        color: "white",
                      },
                    }}
                  >
                    Download as CSV
                  </Button>
                </CSVLink>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginRight: "1rem",
                  }}
                >
                  <TextField
                    id="filled-basic"
                    label="Search"
                    value={searchQueries}
                    variant="outlined"
                    onChange={(e) => setSearchQueries(e.target.value)}
                    type="search"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <SearchIcon onClick={fetchSearchedDashboardData} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    // sx={{
                    //   borderTopRightRadius: "10px",
                    //   borderBottomRightRadius: "10px",
                    // }}
                  />
                </div>

                <DataTable
                  // canSearch={true}
                  column={columns}
                  // tabledata={isSearched === false ? filterData() : dashboardData}
                  tabledata={dashboardData}
                  useHook={tableHooks}
                  dashboardPageNo={dashboardPageNo}
                  dashboardTotalPageCount={dashboardTotalPageCount}
                  onPageChangeHandler={onPageChangeHandler}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
};

export default UserlistTable;
