/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Billing page components
import Device from "layouts/device/components/Device";
import { useState } from "react";

import Modal from "../Modal/InputModal";
// Add New Rule Modal
import AddNewRuleModal from "../AddNewRuleModal/AddNewRuleModal";
function DeviceInformation() {
  const [showAddNewRuleModal, setShowAddNewModal] = useState(false);
  const [showmodal, setshowmodal] = useState(false);
  const [rule, setRule] = useState([]);
  let rules = [];
  function handlemodal() {
    setshowmodal(true);
  }
  function handleData(data) {
    rules.push(data);
  }
  const handleAddNewRule = () => {
    setShowAddNewModal(true)
  };

  console.log(rules);
  return (
    <>
      <AddNewRuleModal
        show={showAddNewRuleModal}
        onHide={() => setShowAddNewModal(false)}
      />
      <Card id="delete-account">
        <MDBox
          pt={2}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <MDTypography variant="h6" fontWeight="medium">
            Rules & Automation
          </MDTypography>
          {/* <MDButton variant="gradient" color="dark" onClick={handlemodal}> */}
          <MDButton variant="gradient" color="dark" onClick={handleAddNewRule}>
            <Icon sx={{ fontWeight: "bold" }}>add</Icon>
            &nbsp;add new rule
          </MDButton>
          {showmodal && (
            <Modal setOpenModal={setshowmodal} handleRule={handleData} />
          )}
        </MDBox>
        <MDBox pt={1} pb={2} px={2}>
          <MDBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            <Device
              name="Morning Temperature"
              rule="If <> between >=22 Temp <=25"
              trigger="Set HVAC to 20°"
              iotactive="Active"
            />
            <Device
              name="Lunch Temperature"
              rule="If Office Sensor 2 = No Motion"
              trigger="Set HVAC to 24° until Motion is Detected"
              iotactive="Active"
            />
            <Device
              name="Evening Temperature"
              rule="If DoorLock is Secure"
              trigger="Check Office Motion = No Motion & Set HVAC to Off"
              iotactive="Active"
              noGutter
            />
          </MDBox>
        </MDBox>
      </Card>
    </>
  );
}

export default DeviceInformation;
