/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React context
import { useMaterialUIController, setLayout } from "context";
function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const isLoggedIn = () => {
    setIsLoading(true);
    if (localStorage.getItem("vccAuthInfo")) {
      const vccAuthInfo = JSON.parse(localStorage.getItem("vccAuthInfo"));
      if (vccAuthInfo?.success === true) {
        setIsLoading(false);
      } else {
        navigate("/login");
      }
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    isLoggedIn();
  }, []);
  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <>
      {isLoading === true ? (
        <div>Loading</div>
      ) : (
        <>
          <MDBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
              p: 3,
              position: "relative",

              [breakpoints.up("xl")]: {
                marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                transition: transitions.create(
                  ["margin-left", "margin-right"],
                  {
                    easing: transitions.easing.easeInOut,
                    duration: transitions.duration.standard,
                  }
                ),
              },
            })}
          >
            {children}
          </MDBox>
        </>
      )}
    </>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
