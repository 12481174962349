import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import styles from "./resetPasswordModal.module.css";

// for Form
import { TextField, InputAdornment } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

// API URL
import { vccConfig } from "widgets/config";

import BeatLoader from "react-spinners/BeatLoader";

const ResetPasswordModal = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  useEffect(() => {
    setErrorMsg("");
    setSuccessMsg("");
    setShowSaveBtn(false);
  }, [props]);

  const handleResetPassword = async () => {
    // Send jsonData to the API endpoint
    try {
      setIsSubmitting(true);
      const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
      const payload = {
        email: props.selectedUserData[2].value,
      };

      const res = await fetch(
        vccConfig.vccAPI.baseURL + vccConfig.vccAPI.auth.resetPassword,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authData.data.token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: props.selectedUserData[2]?.value,
            merchant_id: authData.data.userData.merchant_id
          }),
        }
      );
      const response = await res.json();
      console.log("pass reset api res", response);
      if (response.success === true) {
        console.log("Success:", response.data);
        setErrorMsg("");
        // 
        setSuccessMsg("Password Reset Successful! Please check your email");
        // function myFunction() {
        //   // props.setFetchAgainFlag((prev) => prev + 1);
        //   props.onHide()
        // }
        // setTimeout(myFunction, 2000);
      } else {
        console.error("Error after response:", response.data);
        setErrorMsg("Something Went Wrong! Please Try Again Latter");
        setSuccessMsg("");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMsg("Something Went Wrong! Please Try Again Latter");
      setSuccessMsg("");
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      {...props}
      //   className={styles["addCleanerModal"]}
      //   size="sm"
      //   dialogClassName="modal-90w"
      dialogClassName={styles["deleteUserModal"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
          Reset Password Confirmation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles["deleteUserModal-body"]}>
        {/* <h3> */}
        <Typography variant="h6" color="initial">
          Are You Sure You Want To Reset The Password?{" "}
        </Typography>
        <Typography variant="h6" color="initial">
          Email: {props.selectedUserData[2]?.value}{" "}
          {/* {props.selectedUserData[1]?.email} */}
        </Typography>
        <Typography variant="h8" color="initial" fontWeight={"normal"}>
          Note: A reset password link will be sent to your email.
        </Typography>
        {/* </h3> */}
        {/* <h5></h5> */}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {errorMsg.length > 0 ? (
            <div style={{ color: "red" }}>{errorMsg}</div>
          ) : null}
          {successMsg.length > 0 ? (
            <div style={{ color: "green" }}>{successMsg}</div>
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ display: "flex", justifyContent: "center", color: "black" }}
      >
        {isSubmitting === false ? (
          <Button
            variant="contained"
            onClick={handleResetPassword}
            style={{ width: "20rem" }}
            sx={{
              background: "#BE2528",
              color: "white",
              "&:hover": {
                backgroundColor: "#CB3235",

                borderColor: "#0062cc",
                boxShadow: "none",
              },
            }}
          >
            <div style={{ color: "white" }}>Send Password Reset URL</div>
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled
            style={{ width: "20rem" }}
            sx={{
              background: "#BE2528",
              color: "white",
              "&:hover": {
                backgroundColor: "#CB3235",

                borderColor: "#0062cc",
                boxShadow: "none",
              },
            }}
            // disabled={!showSaveBtn}
          >
            <BeatLoader color="#BE2528" />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ResetPasswordModal;
