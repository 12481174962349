// @mui material components
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import IOTManufacturer from "examples/Cards/IOTManufacturer";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Device page components
import CurrentConfig from "layouts/device/components/CurrentConfig";
import IOTData from "layouts/device/components/IOTData";
import BillingInformation from "layouts/device/components/DeviceInformation";
import Automations from "layouts/device/components/Automations";

// Importing Image Icon to show Online or Offline
import WifiIcon from "@mui/icons-material/Wifi";
import WifiOffIcon from "@mui/icons-material/WifiOff";

// API URL
import { veoraconfig } from "widgets/config";

function Device() {
  let params = new URL(document.location).searchParams;
  let sensorid = params.get("id");

  const [iotdata, setiotdata] = useState({});
  const [time, setTime] = useState("");
  const [firstTime, setFirstTime] = useState(
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );

  useEffect(() => {
    let sensorid = params.get("id");
    console.log("sensor ID->", sensorid);
    const authData = JSON.parse(localStorage.getItem("authInfo"));
    fetch(veoraconfig.veoraapi.url + "device/deviceinfo/" + sensorid, {
      headers: {
        Authorization: `Bearer ${authData.accesstoken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setiotdata(data.result);
        console.log("ata data-> ", data.result);
        setTime(
          new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        );
      });
  }, []);
  // Wont need those
  // let iotdetailsdata = [];
  // iotdata.map((item) => {
  //   if (item.id == sensorid) {
  //     iotdetailsdata.push(item);
  //   }
  // });

  // let deviceid = iotdetailsdata[0]?.id;
  // let devicename = iotdetailsdata[0]?.category;

  // Script for calling data received
  // let status = iotdetailsdata[0]?.status;
  // let statusCodes = []

  // if (status && status.length > 0) {
  //   for (let i = 0; i < status.length; i++) {
  //     let code = status[i].code;
  //     let value = status[i].value;

  //     statusCodes.push({code, value})
  //   }
  // }
  // End of Data received code

  // let temperature = iotdata.status.map((item) => {
  //   if (item.code === "temperature") return item.value;
  // });

  // Battery Status script
  const [batteryPercentage, setBatteryPercentage] = useState("Not Available");

  useEffect(() => {
    if (iotdata.status) {
      const batteryPercentageValue = iotdata.status.find(
        (status) => status.code === "battery_percentage"
      )?.value;
      if (batteryPercentageValue) {
        setBatteryPercentage(batteryPercentageValue + " %");
      } else {
        const batteryStateValue = iotdata.status.find(
          (status) => status.code === "battery_state"
        )?.value;
        if (batteryStateValue) {
          const firstLetter = batteryStateValue.charAt(0).toUpperCase();
          const restOfString = batteryStateValue.slice(1).toLowerCase();
          setBatteryPercentage(firstLetter + restOfString);
        }
      }
    }
  }, [iotdata]);

  let devicestatus =
    iotdata.online === true ? (
      <div>
        <WifiIcon color="success" fontSize="medium" />
      </div>
    ) : (
      <div style={{ color: "red" }}>
        <WifiOffIcon color="danger" fontSize="medium" />
      </div>
    );
  let productcategory = iotdata.category;
  let productName = iotdata.product_name;
  let productIP = iotdata.ip;
  let sensorName = iotdata.name;
  let iotIcon = iotdata.icon;
  let iotSensorOnlineStatus = iotdata.online;
  // let productcategory = iotdetailsdata[0]?.category;
  // let productName = iotdetailsdata[0]?.product_name;
  // let productIP = iotdetailsdata[0]?.ip;
  // let sensorName = iotdetailsdata[0]?.name;
  // let iotIcon = iotdetailsdata[0]?.icon
  // let iotSensorOnlineStatus = iotdetailsdata[0]?.online

  return (
    <DashboardLayout>
      <DashboardNavbar absolute isMini />
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} xl={6}>
                  <IOTManufacturer
                    sensorname={sensorName}
                    pname={productName}
                    pip={productIP}
                    pcategory={productcategory}
                    iconImage={iotIcon}
                    iotOnlineStatus={
                      iotSensorOnlineStatus ? "Online" : "Offline "
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    color="success"
                    icon="account_balance"
                    title="device state"
                    description={"Last Updated: " + time}
                    value={devicestatus}
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                  <DefaultInfoCard
                    icon="paypal"
                    title="battery"
                    description={"Last Updated: " + time}
                    value={
                      batteryPercentage !== "Not Available"
                        ? batteryPercentage
                        : "No Battery"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <CurrentConfig
                    updateTime={
                      veoraconfig.iotDevicesConfigUpdateTime.globalUpdateTime /
                        1000 +
                      " seconds"
                    }
                    updateConfig={
                      veoraconfig.iotDevicesConfigEnable.globalEnableByDefault
                        ? "Enabled"
                        : "Disabled"
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              {/* <IOTData
                onecode={iotdata.status[0] ? iotdata.status[0].code : "No data"}
                onevalue={
                  iotdata.status[0] ? iotdata.status[0].value : "No data"
                }
              /> */}
              {/* <IOTData
                statusData={iotdata?.status}
                // onecode={iotdata.status[0] ? iotdata.status[0].code : "No data"}
                // onevalue={
                //   iotdata.status[0] ? iotdata.status[0].value : "No data"
                // }
                // twocode={statusCodes[1] ? statusCodes[1].code : "No data" }
                // twovalue={statusCodes[1] ? statusCodes[1].value : "No data" }
                // threecode={statusCodes[2] ? statusCodes[2].code : "No data" }
                // threevalue={statusCodes[2] ? statusCodes[2].value : "No data" }
                // fourcode={statusCodes[3] ? statusCodes[3].code : "No data" }
                // fourvalue={statusCodes[3] ? statusCodes[3].value : "No data" }
                // fivecode={statusCodes[4] ? statusCodes[4].code : "No data" }
                // fivevalue={statusCodes[4] ? statusCodes[4].value : "No data" }
              /> */}
              <IOTData
                statusData={iotdata?.status}
                />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <BillingInformation />
              
            </Grid>
            <Grid item xs={12} md={5}>
              <Automations />
              
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Device;
