import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Import stylesheet
import styles from "./sidenav.module.css";
// Loading spinner
import RiseLoader from "react-spinners/RiseLoader";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Logout Confirmation Modal
import LogoutConfirmationModal from "./components/logoutConfirmationModal/LogoutConfirmationModal";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { useState } from "react";

// API URL
import { veoraconfig } from "widgets/config";

import vccLogo from "../../assets/vccAssets/vccLogo.png";
import prsLogo from "../../assets/vccAssets/prs_logo.png";

// PrivateRouteWrapper component only render its children if user is verified
import PrivateRouteWrapper from "../../layouts/authentication/PrivateRouteWrapper";

function Sidenav({ color, brand, product, brandName, routes, ...rest }) {
  const [logoutConfirmationModalOpen, setLogoutConfirmationModalOpen] =
    useState(false);
  const [isLogoutConfirmed, setIsLogoutConfirmed] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [locallyStoredAuthToken, setLocallyStoredAuthToken] = useState(
    localStorage.getItem("authInfo")
  );
  const [sidebarLoading, setSidebarLoading] = useState(false);
  const [locallyStoredPermittedPages, setLocallyStoredPermittedPages] =
    useState(localStorage.getItem("permittedPages"));
  const [accessPages, setAccessPages] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const [openiotdevicesoption, setopeniotdevicesoption] = useState(() => {
    // if (collapseName === "air") {
    //   return true;
    // } else {
    //   return false;
    // }
    let submenuArray = ["air", "lighting", "security", "services"];
    return submenuArray.includes(collapseName);
  });

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);
  const navigate = useNavigate();

  const getPermittedPagesID = async () => {
    setSidebarLoading(true);
    const authData = JSON.parse(localStorage.getItem("authInfo"));
    try {
      const res = await fetch(
        // "https://veora-dev-server.azurewebsites.net/api/v1/auth/tokenverify",
        veoraconfig.veoraapi.url + veoraconfig.auth.tokenVerify,
        {
          method: "POST",
          // body: JSON.stringify({
          //   token: accessToken,
          // }),
          headers: {
            Authorization: `Bearer ${authData.accesstoken}`,
            "Content-type": "application/json",
          },
        }
      );
      const data = await res.json();
      // console.log(data);
      if (res.status === 200 && data.success === true) {
        const pageIDs = data.data.page_permission.map(
          (perPage) => perPage.pageid
        );
        localStorage.setItem(
          "permittedPages",
          JSON.stringify(data.data.page_permission)
        );
        // localStorage.setItem("permittedPages", JSON.stringify(pageIDs));
        setLocallyStoredPermittedPages(localStorage.getItem("permittedPages"));
        setAccessPages(pageIDs);
        setSidebarLoading(false);
      } else {
        console.log("invalid token");
        localStorage.removeItem("authInfo");
        localStorage.removeItem("permittedPages");
        navigate("/login");
      }
    } catch (err) {
      return false;
    }
  };

  const handleLogout = () => {
    setLogoutConfirmationModalOpen(true);
  };

  useEffect(() => {
    if (isLogoutConfirmed === true) {
      localStorage.removeItem("vccAuthInfo");
      localStorage.removeItem("archivedTransactions");
      localStorage.removeItem("showArchived");
      navigate("/login");
    }
  }, [isLogoutConfirmed, setIsLogoutConfirmed]);

  useEffect(() => {
    // getPermittedPagesID();
  }, []);

  // This will check if the local storage token is being changed or not. if changed it will re-check verify token STARTS
  // useEffect(() => {
  //   const checkLocalStorage = () => {
  //     console.log("checking if token is changed");
  //     const tokenFromCurrentLocalStorage = localStorage.getItem("authInfo");
  //     const permittedPagesFromCurrentLocalStorage =
  //       localStorage.getItem("permittedPages");
  //     if (tokenFromCurrentLocalStorage !== locallyStoredAuthToken) {
  //       console.log("token changed");
  //       // Token in localStorage has changed, do something
  //       getPermittedPagesID();
  //       // setStoredToken(tokenFromLocalStorage);
  //     }
  //     if (
  //       locallyStoredPermittedPages !== permittedPagesFromCurrentLocalStorage
  //     ) {
  //       console.log("permitted page list is changed");

  //       getPermittedPagesID();
  //     }
  //   };
  //   // Check every 1 second for changes
  //   const intervalId = setInterval(checkLocalStorage, 3000);

  //   // Clean up the interval on unmount
  //   return () => clearInterval(intervalId);
  // }, [locallyStoredAuthToken, locallyStoredPermittedPages]);
  // This will check if the local storage token is being changed or not. if changed it will re-check verify token ENDS

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
    // setUserType(authData?.data?.userType)
    if (authData?.data?.userType === "User") {
      setSelectedRoute(["dashboard", "support", "profile", "logout"]);
    } else if (authData?.data?.userType === "Admin") {
      setSelectedRoute(["admin-panel", "settings", "logout"]);
    }
  }, []);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes
    // .filter((route) => accessPages.includes(route.pageID))
    .filter((route) => selectedRoute.includes(route.key))
    .map(
      ({
        type,
        name,
        icon,
        title,
        noCollapse,
        key,
        href,
        route,
        isSubmenu,
      }) => {
        let returnValue;

        if (type === "collapse") {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          ) : (
            // <>
            // <NavLink key={key} to={route}>
            //   <SidenavCollapse name={name} icon={icon} active={key === collapseName} />
            // </NavLink>
            // </>
            <>
              {key === "iot-devices" ? (
                <>
                  {/* <IotDevicesOptions/> */}
                  <div
                    className="iot-devices-toggle-btn-wrapper"
                    onClick={() => setopeniotdevicesoption((prev) => !prev)}
                  >
                    <div>
                      <NavLink key="Iot Devices" to="#">
                        <SidenavCollapse
                          name="IoT Devices"
                          icon={icon}
                          active={false}
                          openiotdevicesoption={openiotdevicesoption}
                        />
                      </NavLink>
                    </div>
                  </div>
                  {openiotdevicesoption === true ? (
                    <>
                      <div className={styles[`iot-options-wrapper`]}>
                        {accessPages.includes(
                          "66ed5fdc2f374363882abc76f6848adb"
                        ) && (
                          <NavLink key="iot-devices" to="/iot-devices">
                            <SidenavCollapse
                              name="All IoT Devices"
                              icon={<Icon fontSize="medium">sim_card</Icon>}
                              active={"iot-devices" === collapseName}
                            />
                          </NavLink>
                        )}

                        <NavLink key="air" to="/air">
                          <SidenavCollapse
                            name="Air Quality"
                            icon={<Icon fontSize="medium">air</Icon>}
                            active={"air" === collapseName}
                          />
                        </NavLink>
                        <NavLink key="lighting" to="/lighting">
                          <SidenavCollapse
                            name="Lighting"
                            icon={
                              <Icon fontSize="medium">tips_and_updates</Icon>
                            }
                            active={"lighting" === collapseName}
                          />
                        </NavLink>
                        <NavLink key="security" to="/security">
                          <SidenavCollapse
                            name="Security"
                            icon={<Icon fontSize="medium">local_police</Icon>}
                            active={"security" === collapseName}
                          />
                        </NavLink>
                        <NavLink key="services" to="/services">
                          <SidenavCollapse
                            name="Services"
                            icon={
                              <Icon fontSize="medium">electrical_services</Icon>
                            }
                            active={"services" === collapseName}
                          />
                        </NavLink>
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {isSubmenu === false ? (
                    <NavLink key={key} to={route}>
                      <SidenavCollapse
                        name={name}
                        icon={icon}
                        // active={false}
                        active={key === collapseName}
                      />
                    </NavLink>
                  ) : null}
                </>
              )}
            </>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }
        if (key === "logout") {
          // Render the logout menu
          // {alert("Hello")}
          returnValue = (
            // <NavLink key="" to="/login" onClick={() => handleLogout()}>
            <div onClick={() => handleLogout()}>
              <SidenavCollapse
                name="Logout"
                icon={<Icon fontSize="medium">logout</Icon>}
                active={"services" === collapseName}
              />
            </div>
            // </NavLink>
          );
        }

        return returnValue;
      }
    );

  return (
    <>
      <LogoutConfirmationModal
        show={logoutConfirmationModalOpen}
        onHide={() => setLogoutConfirmationModalOpen(false)}
        setIsLogoutConfirmed={setIsLogoutConfirmed}
        // setFetchAgainFlag={setFetchAgainFlag}
      />
      <>
        {/* <PrivateRouteWrapper> */}
        <div style={{ overflow: "hidden" }}>
          <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{
              transparentSidenav,
              whiteSidenav,
              miniSidenav,
              darkMode,
              // sidenavColor,
            }}
          >
            <MDBox pt={3} pb={1} px={4} textAlign="center">
              <MDBox
                display={{ xs: "block", xl: "none" }}
                position="absolute"
                top={0}
                right={0}
                p={1.625}
                onClick={closeSidenav}
                sx={{ cursor: "pointer" }}
              >
                <MDTypography variant="h6" color="secondary">
                  <Icon sx={{ fontWeight: "bold" }}>close</Icon>
                </MDTypography>
              </MDBox>
              <MDBox
                component={NavLink}
                to="/"
                display="flex"
                alignItems="center"
              >
                {brand && (
                  <MDBox
                    component="img"
                    src={prsLogo}
                    alt="Veora"
                    width="20rem"
                    height="4rem"
                    sx={{ transform: "scale(1.3)" }}
                    //sx={{ mb: "2%" }}
                  />
                )}
              </MDBox>
              <Divider
                light={
                  (!darkMode && !whiteSidenav && !transparentSidenav) ||
                  (darkMode && !transparentSidenav && whiteSidenav)
                }
              />
            </MDBox>

            {/* <List>LOADING</List> */}
            {sidebarLoading === true ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "2rem",
                  justifyContent: "center",
                  // alignContent: "end",
                  alignItems: "center",
                  // background: 'red',
                  height: "100%",
                  fontWeight: "bold",
                }}
              >
                <div>Please Wait...</div>

                <RiseLoader color="#FE3080" />
              </div>
            ) : (
              <>
                <List>{renderRoutes}</List>
              </>
            )}

            <MDBox p={2} mt="auto"></MDBox>
          </SidenavRoot>
        </div>
        {/* </PrivateRouteWrapper> */}
      </>
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "primary",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "washroomcolor",
    "veoracolor",
    "veoracolor2",
    "veorasidenav",
  ]),
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
