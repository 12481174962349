/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfileInfoCard from "./components/ProfileInfoCard";


// Profile Pasword Modal
import EditNetsuiteHostUrlModal from "./components/EditNetsuiteHostUrlModal/EditNetsuiteHostUrlModal";
// Profile info edit modal
import ProfieInfoEditModal from "./components/ProfileInfoEditModal/ProfileInfoEditModal";
// Import Header
import Header from "modules/Header";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";

// API endpoints
import { vccConfig } from "widgets/config";
import BeatLoader from "react-spinners/BeatLoader";


function Overview() {
  const [createNetsuiteHostUrlModalOpen, setCreateNetsuiteHostUrlModalOpen] =
    useState(false);
  const [profileInfoEditModalOpen, setProfileInfoEditModalOpen] =
    useState(false);
  const [netsuiteEndPoint, setNetsuiteEndpoint] = useState("");
  const [fetchAgainFlag, setFetchAgainFlag] = useState(0)
  const fetchNetsuiteEndpoint = async () => {
    
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
    const marchentId = authData?.data?.userData?.merchant_id
    console.log('merchant id', marchentId)
    try {
      const response = await fetch(
        vccConfig.vccAPI.baseURL +
          vccConfig.vccAPI.settings.fetchNetsuiteEndPoint +
          `?merchant_id=${marchentId}`,
        {
          headers: {
            Authorization: `Bearer ${authData.data.token}`,
            "Content-Type": "application/json",
          },
        }
      ); // Replace with your API endpoint
      const data = await response.json();
      console.log(data);
      if (data.success === true) {
        console.log("netsuite data", data?.data);
        setNetsuiteEndpoint(data?.data?.netsuite_url[0]?.host_url);
        // setUserData(data.data.user);
      } else {
        console.log("success: false");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchNetsuiteEndpoint();
  }, [fetchAgainFlag]);
  return (
    <>
      <EditNetsuiteHostUrlModal
        show={createNetsuiteHostUrlModalOpen}
        onHide={() => setCreateNetsuiteHostUrlModalOpen(false)}
        setFetchAgainFlag={setFetchAgainFlag}
      />
      <ProfieInfoEditModal
        show={profileInfoEditModalOpen}
        onHide={() => setProfileInfoEditModalOpen(false)}
      />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <Header pageName="profile">
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
              <Grid
                container
                justifyContent={"center"}
                item
                xs={12}
                md={12}
                xl={12}
                // sx={{width: '15rem'}}
                // sx={{ display: "flex", justifyContent: "center" }}
              >
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                <ProfileInfoCard
                  netsuiteHostUrl={
                    netsuiteEndPoint?.length === 0 ? (
                      <span>
                        <BeatLoader color="#36d7b7" />
                      </span>
                    ) : (
                      netsuiteEndPoint
                    )
                  }
                  title="Netsuite Host URL Panel"
                  description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                  info={
                    {
                      // netsuiteHostUrl: "Alec M. Thompson",
                      // mobile: "(44) 123 1234 123",
                      // email: "alecthompson@mail.com",
                      // location: "USA",
                    }
                  }
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                  editBtnAction={() => setProfileInfoEditModalOpen(true)}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              {/* <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid> */}
            </Grid>
          </MDBox>
          <MDBox>
            <Grid container item justifyContent={"center"}>
              <Button
                variant="outlined"
                onClick={() => setCreateNetsuiteHostUrlModalOpen(true)}
              >
                <span
                  style={{
                    color: "black",
                  }}
                >
                  Change netsuite host url
                </span>
              </Button>
            </Grid>
          </MDBox>
          {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
          Projects
          </MDTypography>
          <MDBox mb={1}>
          <MDTypography variant="button" color="text">
          Architects design houses
          </MDTypography>
          </MDBox>
        </MDBox> */}
          {/* <MDBox p={2}>
          <Grid container spacing={6}>
          <Grid item xs={12} md={6} xl={3}>
          <DefaultProjectCard
          image={homeDecor1}
          label="project #2"
          title="modern"
          description="As Uber works through a huge amount of internal management turmoil."
          action={{
            type: "internal",
            route: "/pages/profile/profile-overview",
            color: "info",
            label: "view project",
          }}
          authors={[
            { image: team1, name: "Elena Morison" },
            { image: team2, name: "Ryan Milly" },
            { image: team3, name: "Nick Daniel" },
            { image: team4, name: "Peterson" },
          ]}
          />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
          <DefaultProjectCard
          image={homeDecor2}
          label="project #1"
          title="scandinavian"
          description="Music is something that everyone has their own specific opinion about."
          action={{
            type: "internal",
            route: "/pages/profile/profile-overview",
            color: "info",
            label: "view project",
          }}
          authors={[
            { image: team3, name: "Nick Daniel" },
            { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
                />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
                />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
                />
                </Grid>
                </Grid>
              </MDBox> */}
        </Header>
        {/* <Footer /> */}
      </DashboardLayout>
    </>
  );
}

export default Overview;
