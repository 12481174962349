import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import styles from "./userCreateModal.module.css";

// for Form
import { TextField, InputAdornment, Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

// API URL
import axios from "axios";
import { vccConfig } from "widgets/config";

// for hashing
import bcrypt from "bcryptjs";
import BeatLoader from "react-spinners/BeatLoader";

const salt = bcrypt.genSaltSync(10);

const UserCreateModal = (props) => {
  const [userCreateFormData, setUserCreateFormData] = useState({
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    merchant_id: "",
    email: "",
    user_type: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  useEffect(() => {
    setUserCreateFormData({
      username: "",
      password: "",
      first_name: "",
      last_name: "",
      merchant_id: "",
      email: "",
      user_type: "",
    });
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(false);
  }, [props.show]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setUserCreateFormData({
      ...userCreateFormData,
      [name]: value,
    });
  };
  const handleFormDataSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");
    const hashedPassword = bcrypt.hashSync(userCreateFormData.password, salt);
    // console.log(first)
    setIsSubmitting(true);
    try {
      const response = await axios({
        method: "POST",
        url: vccConfig.vccAPI.baseURL + vccConfig.vccAPI.auth.register,
        data: {
          // username: userCreateFormData.username,
          // password: userCreateFormData.password,
          password: hashedPassword,
          first_name: userCreateFormData.first_name,
          last_name: userCreateFormData.last_name,
          merchant_id: userCreateFormData.merchant_id,
          email: userCreateFormData.email,
          user_type: userCreateFormData.user_type,
        },
      });
      console.log("user create api res", response);
      const res = response.data;
      if (res.success === true) {
        setErrorMsg("");
        setSuccessMsg(res.message);
        props.setFetchAgainFlag((prev) => prev + 1);
      } else {
        setSuccessMsg("");
        setErrorMsg(res.message);
      }
    } catch (error) {
      console.log("error in catch", error?.response?.data?.message);
      setSuccessMsg("");

      // setErrorMsg("Could not register. Please try again later!");
      setErrorMsg(error?.response?.data?.message);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      {...props}
      //   className={styles["addCleanerModal"]}
      //   size="sm"
      //   dialogClassName="modal-90w"
      dialogClassName={styles["deleteUserModal"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
          Create User
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles["deleteUserModal-body"]}>
        <FormControl
          className={styles["userPassEditModal-control"]}
          component="form"
          onSubmit={handleFormDataSubmit}
          fullWidth
        >
          {successMsg.emailCodeSuccessMsg && (
            <Typography variant="h7" color="green" align="center">
              {successMsg.emailCodeSuccessMsg}
            </Typography>
          )}
          {errorMsg.emailCodeErrorMsg && (
            <Typography variant="h7" color="red" align="center">
              {errorMsg.emailCodeErrorMsg}
            </Typography>
          )}
          {/* <TextField
            fullWidth
            // type="password"
            required
            label="Username"
            id="username"
            name="username"
            value={userCreateFormData.username}
            onChange={handleFormDataChange}
          /> */}
          <TextField
            fullWidth
            // type="password"
            required
            label="First Name"
            id="first_name"
            name="first_name"
            value={userCreateFormData.first_name}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            required
            label="Last Name"
            id="last_name"
            name="last_name"
            value={userCreateFormData.last_name}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            required
            label="Merchant ID"
            id="merchant_id"
            name="merchant_id"
            value={userCreateFormData.merchant_id}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            type="email"
            required
            label="Email"
            id="email"
            name="email"
            value={userCreateFormData.email}
            onChange={handleFormDataChange}
          />
          <FormControl fullWidth>
            <InputLabel id="user_type-label">User Type *</InputLabel>
            <Select
              labelId="user_type"
              size="normal"
              label="User Type *"
              required
              id="user_type"
              name="user_type"
              value={userCreateFormData.user_type}
              onChange={handleFormDataChange}
              style={{ height: "2.8rem" }}
            >
              <MenuItem value="User">User</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            type="password"
            required
            label="Password"
            id="password"
            name="password"
            value={userCreateFormData.password}
            onChange={handleFormDataChange}
          />

          {/* <FormControlLabel
            required
            control={<Checkbox />}
            label="I have read and agree to the terms"
          /> */}
          {errorMsg && (
            <Typography variant="h7" color="red">
              {errorMsg}
            </Typography>
          )}
          {successMsg && (
            <Typography variant="h7" color="green">
              {successMsg}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            style={{ background: "#2E4082", color: "white",borderRadius:"8px" }}
          >
            {isSubmitting === true ? "Please Wait" : "Create User"}
          </Button>
        </FormControl>
      </Modal.Body>
      <Modal.Footer
        style={{ display: "flex", justifyContent: "center", color: "black" }}
      ></Modal.Footer>
    </Modal>
  );
};

export default UserCreateModal;
