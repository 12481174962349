/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfileInfoCard from "./components/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import { TextField, InputAdornment } from "@mui/material";
import Select from "@mui/material/Select";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import { FormControl, Typography } from "@mui/material";
import axios from "axios";
import { vccConfig } from "widgets/config";

// Profile Pasword Modal
import CreateSupportTicketModal from "./components/CreateSupportTicketModal/CreateSupportTicketModal";
// Profile info edit modal
import ProfieInfoEditModal from "./components/ProfileInfoEditModal/ProfileInfoEditModal";
// Import Header
import Header from "modules/Header";
import { Button } from "@mui/material";
import { useState } from "react";

import styles from "./support.module.css";

function Overview() {
  const [createSupportTicketModalOpen, setCreateSupportTicketModalOpen] =
    useState(false);
  const [profileInfoEditModalOpen, setProfileInfoEditModalOpen] =
    useState(false);
  const [createSupportFormData, setCreateSupportFormData] = useState({
    username: "",
    email: "",
    transaction_id: "",
    message: "",
    // status: "NEW",
    merchant_id: "",
    priority: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerificationCodeLoading, setIsVerificationCodeLoading] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setCreateSupportFormData({
      ...createSupportFormData,
      [name]: value,
    });
  };

  const handleFormDataSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");

    setIsSubmitting(true);
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));
    const vccUsername = localStorage.getItem("vccUsername");
    try {
      const response = await axios({
        method: "POST",
        url:
          vccConfig.vccAPI.baseURL +
          vccConfig.vccAPI.supportTicket.supportTicket,
        headers: {
          Authorization: `Bearer ${authData.data.token}`,
          "Content-Type": "application/json",
        },
        data: {
          username: vccUsername,
          email: createSupportFormData.email,
          transaction_id: createSupportFormData.transaction_id,
          message: createSupportFormData.message,
          status: "NEW",
          merchant_id: `${authData.data.userData.merchant_id}`,
          priority: createSupportFormData.priority,
        },
      });
      console.log("create support api res", response);
      const res = response.data;
      if (res.success === true) {
        setErrorMsg("");
        setSuccessMsg(res.message);
      } else {
        setSuccessMsg("");
        setErrorMsg("Could not create support ticket. Please try again later!");
      }
    } catch (error) {
      console.log("error in catch", error);
      setSuccessMsg("");

      setErrorMsg("Could not create support ticket. Please try again later!");
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <CreateSupportTicketModal
        show={createSupportTicketModalOpen}
        onHide={() => setCreateSupportTicketModalOpen(false)}
      />
      <ProfieInfoEditModal
        show={profileInfoEditModalOpen}
        onHide={() => setProfileInfoEditModalOpen(false)}
      />
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={2} />
        <Header pageName="profile">
          <MDBox mt={5} mb={3}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} md={6} xl={4}>
              <PlatformSettings />
            </Grid> */}
              <Grid
                container
                justifyContent={"center"}
                // alignItems={'center'}
                item
                xs={12}
                md={12}
                xl={6}
                mt={3}
                // sx={{width: '15rem'}}
                // sx={{ display: "flex", justifyContent: "center" }}
              >
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                <ProfileInfoCard
                  title="profile information"
                  description="Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
                  info={{
                    fullName: "Alec M. Thompson",
                    mobile: "(44) 123 1234 123",
                    email: "alecthompson@mail.com",
                    location: "USA",
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: <FacebookIcon />,
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: <TwitterIcon />,
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: <InstagramIcon />,
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                  editBtnAction={() => setProfileInfoEditModalOpen(true)}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={12}
                xl={6}
                justifyContent={"center"}
                sx={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Adjust the shadow as needed
                  borderRadius: "10px", // Adjust the border-radius as needed
                  padding: "16px", // Optional: Add padding for a more spacious look
                }}
              >
                {/* <ProfilesList title="conversations" profiles={profilesListData} shadow={false} /> */}
                <>
                  <Typography
                    variant="h3"
                    color="initial"
                    align="center"
                    mb={4}
                  >
                    Create Support Ticket
                  </Typography>
                  <FormControl
                    className={styles["userPassEditModal-control"]}
                    component="form"
                    onSubmit={handleFormDataSubmit}
                    fullWidth
                  >
                    {successMsg.emailCodeSuccessMsg && (
                      <Typography variant="h7" color="green" align="center">
                        {successMsg.emailCodeSuccessMsg}
                      </Typography>
                    )}
                    {errorMsg.emailCodeErrorMsg && (
                      <Typography variant="h7" color="red" align="center">
                        {errorMsg.emailCodeErrorMsg}
                      </Typography>
                    )}
                    <TextField
                      fullWidth
                      // type="password"
                      required
                      label="Email"
                      id="email"
                      name="email"
                      value={createSupportFormData.email}
                      onChange={handleFormDataChange}
                    />
                    <TextField
                      fullWidth
                      // type="password"
                      // required
                      label="Transaction ID (Optional)"
                      id="transaction_id"
                      name="transaction_id"
                      value={createSupportFormData.transaction_id}
                      onChange={handleFormDataChange}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="priority-label">Priority *</InputLabel>
                      <Select
                        labelId="priority"
                        size="normal"
                        label="Priority *"
                        required
                        id="priority"
                        name="priority"
                        value={createSupportFormData.priority}
                        onChange={handleFormDataChange}
                        style={{ height: "2.8rem" }}
                      >
                        <MenuItem value="Low">Low</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="High">High</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      fullWidth
                      // type="password"
                      multiline
                      rows={8}
                      required
                      label="Message"
                      id="message"
                      name="message"
                      value={createSupportFormData.message}
                      onChange={handleFormDataChange}
                    />

                    {/* <FormControlLabel
            required
            control={<Checkbox />}
            label="I have read and agree to the terms"
          /> */}
                    {errorMsg && (
                      <Typography variant="h7" color="red" align="center">
                        {errorMsg}
                      </Typography>
                    )}
                    {successMsg && (
                      <Typography variant="h7" color="green" align="center">
                        {successMsg}
                      </Typography>
                    )}
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      
                      disabled={isSubmitting}
                      sx={{ color:"#ffff",background: "#2E4082",
                      "&:hover": {
                        backgroundColor: "#000000",
                        borderColor: "#ff0062",
                        boxShadow: "none",
                      }, }}
                    >
                      {isSubmitting === true ? "Please Wait" : "Submit"}
                    </Button>
                  </FormControl>
                </>
              </Grid>
            </Grid>
          </MDBox>
          {/* <MDBox>
              <Grid container item justifyContent={"center"}>
                <Button
                  variant="outlined"
                  onClick={() => setCreateSupportTicketModalOpen(true)}
                >
                  <span
                    style={{
                      color: "black",
                    }}
                  >
                    Create a support ticket
                  </span>
                </Button>
              </Grid>
            </MDBox> */}
          {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
          Projects
          </MDTypography>
          <MDBox mb={1}>
          <MDTypography variant="button" color="text">
          Architects design houses
          </MDTypography>
          </MDBox>
        </MDBox> */}
          {/* <MDBox p={2}>
          <Grid container spacing={6}>
          <Grid item xs={12} md={6} xl={3}>
          <DefaultProjectCard
          image={homeDecor1}
          label="project #2"
          title="modern"
          description="As Uber works through a huge amount of internal management turmoil."
          action={{
            type: "internal",
            route: "/pages/profile/profile-overview",
            color: "info",
            label: "view project",
          }}
          authors={[
            { image: team1, name: "Elena Morison" },
            { image: team2, name: "Ryan Milly" },
            { image: team3, name: "Nick Daniel" },
            { image: team4, name: "Peterson" },
          ]}
          />
          </Grid>
          <Grid item xs={12} md={6} xl={3}>
          <DefaultProjectCard
          image={homeDecor2}
          label="project #1"
          title="scandinavian"
          description="Music is something that everyone has their own specific opinion about."
          action={{
            type: "internal",
            route: "/pages/profile/profile-overview",
            color: "info",
            label: "view project",
          }}
          authors={[
            { image: team3, name: "Nick Daniel" },
            { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
                />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
                />
                </Grid>
                <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
                />
                </Grid>
                </Grid>
              </MDBox> */}
        </Header>
        {/* <Footer /> */}
      </DashboardLayout>
    </>
  );
}

export default Overview;
