/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
**/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import updateTimeIcon from "assets/images/logos/updateTimeIcon.svg";
import powerIcon from "assets/images/logos/powerIcon.svg";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

function CurrentConfig(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  let updateTime=props.updateTime
  let updateConfig=props.updateConfig

  return (
    <Card id="delete-account">
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDTypography variant="h6" fontWeight="medium">
          Configuration
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MDBox
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <MDBox component="img" src={updateTimeIcon} alt="Time IoT device Refreshes" width="10%" mr={2} />
                <MDTypography variant="h6" fontWeight="medium">
                  {updateTime}
                </MDTypography>

            </MDBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MDBox
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={3}
              sx={{
                border: ({ borders: { borderWidth, borderColor } }) =>
                  `${borderWidth[1]} solid ${borderColor}`,
              }}
            >
              <MDBox component="img" src={powerIcon} alt="Emergency Stop" width="10%" mr={2} />
              <MDTypography variant="h6" fontWeight="medium">
                {updateConfig}<Switch checked />
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default CurrentConfig;
