/**
=========================================================
========== Omor's coding for Password Reset Form system v1.0
=========================================================
*/

import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// react-loader-spinner
import { ThreeDots } from "react-loader-spinner";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// API URL
import { veoraconfig } from "widgets/config";

// Images
import bgImage from "assets/images/bg-sign-in-veora.jpg";

// VerifyToken function to verify the private route/api
// import verifyToken from "../verifyToken";

// forget password Modal
// import ForgetPasswordModal from "../components/ForgetPasswordModal/ForgetPasswordModal";

function PasswordResetForm() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  //   const [rememberMe, setRememberMe] = useState(false);
  //   const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isError, setError] = useState(false);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [isResettingPassLoading, setIsResettingPassLoading] = useState(false);
  const [passwordMissMatch, setPasswordMissMatch] = useState(false);
  //   const [forgetPasswordModalShow, setForgetPasswrodModalShow] = useState(false);

  //   const handleSetRememberMe = () => setRememberMe(!rememberMe);
  let navigate = useNavigate();

  

  const verifyPasswordResetToken = async () => {
    try {
    
      const res = await fetch(
        // `https://veora-dev-server.azurewebsites.net/api/v1/auth/verifyresettoken?token=${token}`,
        `${veoraconfig.veoraapi.url}${veoraconfig.auth.verifyPasswordResetToken}${token}`,
        {
          method: "POST",
          // body: JSON.stringify({
          //   password: newPassword,
          //   token: token,
          // }),
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const data = await res.json();
      console.log("token verify er data", data)
      if (data.success === true) {
        console.log("token verified")
        setIsLoading(false);
      } else {
        console.log("token inValid");
        setIsTokenInvalid(true)
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    console.log("this is the token", token)
    verifyPasswordResetToken();
    // console.log(first)
  }, [token]);

  useEffect(() => {
    if (newPassword.length > 0 && newPassword === confirmPassword) {
      setPasswordMissMatch(false);
    } else {
      setPasswordMissMatch(true);
    }
  }, [newPassword, confirmPassword]);

  const passwordResetHandler = async (e) => {
    e.preventDefault();

    console.log(newPassword, confirmPassword);
    try {
      setIsResettingPassLoading(true);
      const res = await fetch(
        // "https://veora-dev-server.azurewebsites.net/api/v1/auth/reset_password",
        veoraconfig.veoraapi.url + veoraconfig.auth.resetPassword,
        {
          method: "POST",
          body: JSON.stringify({
            password: newPassword,
            token: token,
          }),
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      const data = await res.json();
      console.log(data.message);
      if(data.success === true) {
        navigate("/login")
      }
      setIsResettingPassLoading(false);

    } catch (err) {
      console.log(err);
      setError(true);
      setIsResettingPassLoading(false);
    }
  };

  return (
    <>
      {isTokenInvalid === true ? (
        <> Something Went Wrong. Try Again Later</>
      ) : (
        <>
          {isLoading === false ? (
            <>
              <BasicLayout image={bgImage}>
                <Card>
                  <MDBox
                    bgColor="veoracolor2"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                  >
                    <MDTypography
                      variant="h4"
                      fontWeight="medium"
                      color="white"
                      mt={1}
                    >
                      Password Reset
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={4} pb={3} px={3}>
                    <MDBox
                      component="form"
                      onSubmit={passwordResetHandler}
                      role="form"
                    >
                      {/* <MDBox mb={2}>
                    <MDInput
                      type="email"
                      required
                      label="email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </MDBox> */}
                      <MDBox mb={2}>
                        <MDInput
                          type="password"
                          label="New Password"
                          required
                          fullWidth
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="password"
                          label="Confirm Password"
                          required
                          fullWidth
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </MDBox>

                      {isError === true ? (
                        <>
                          <MDBox mt={2} mb={1} textAlign="center">
                            <MDTypography
                              color="error"
                              fontWeight="light"
                              // verticalAlign="middle"
                              variant="h6"
                            >
                              Something Went Wrong! Please try again later.
                            </MDTypography>
                          </MDBox>
                        </>
                      ) : null}

                      <MDBox mt={3} mb={1}>
                        {isResettingPassLoading === false ? (
                          <>
                            <MDButton
                              type="submit"
                              color="veoracolor2"
                              fontWeight="light"
                              fullWidth
                              disabled={passwordMissMatch}
                            >
                              Reset Password
                            </MDButton>
                          </>
                        ) : (
                          <>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="veoracolor2"
                              fullWidth
                              disabled
                            >
                              <ThreeDots
                                height="25"
                                width="25"
                                radius="9"
                                color="veoracolor2"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </MDButton>
                          </>
                        )}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </BasicLayout>
            </>
          ) : (
            <div>Loading....</div>
          )}
        </>
      )}
    </>
  );
}

export default PasswordResetForm;
