
import "./Modal.css";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import {useState} from 'react'

function Modal({ setOpenModal,handleRule }) {
  const ruleList=[]
  const [upLim,setupLim]=useState()
  const [lowLim,setlowLim]=useState()
  let rule={}
  const rulelist=[]
  function modalHandler(e)
  {
    
     rule={
      'upperlim':upLim,
      'lowerlim':lowLim
    }
    
    handleRule(rule)
  }
  
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <MDBox component="form"  role="form">
            <MDBox mt={2}>
              <MDInput
                type="text"
                required
                label="Upper Limit"
                fullWidth
                onChange={(e)=>setupLim(e.target.value)}
                value={upLim}
                
              />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                type="text"
                label="Lower Limit"
                fullWidth
                onChange={(e)=>setlowLim(e.target.value)}
                value={lowLim}
              
              />
            </MDBox>
          </MDBox>
          <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth onClick={modalHandler}>
                Save
              </MDButton>
            </MDBox>
        </div>
      </div>
    
  );
}

export default Modal
