
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
import pattern from "assets/images/illustrations/IOT.svg";
import tuyaLogo from "assets/images/logos/tuya.png";

function IOTManufacturer({ color, sensorname, pname, pip, pcategory, iconImage, iotOnlineStatus }) {
  return (
    <Card
      sx={({ palette: { gradients }, functions: { linearGradient }, boxShadows: { xl } }) => ({
        background: gradients[color]
          ? linearGradient(gradients[color].main, gradients[color].state)
          : linearGradient(gradients.dark.main, gradients.dark.state),
        boxShadow: xl,
        position: "relative",
      })}
    >
      <MDBox
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        opacity={0.7}
        sx={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
        }}
      />
      <MDBox position="relative" zIndex={2} p={2} justifyContent="space-between" alignItems="center">

        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center">
            <MDBox mr={3} lineHeight={1}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Status
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {iotOnlineStatus}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" width="40%">
            <MDBox component="img" src={tuyaLogo} alt="IoT Platform" width="60%" mt={1} />
          </MDBox>
        </MDBox>

        <MDTypography variant="h5" color="white" fontWeight="medium" sx={{ mt: 3, mb: 2.5, pb: 1 }} alignItems="center">
          {sensorname}
        </MDTypography>

        <MDBox display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex" alignItems="center">
            <MDBox mr={3} lineHeight={1}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Product IP
              </MDTypography>
              <MDTypography
                variant="h6"
                color="white"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {pip}
              </MDTypography>
            </MDBox>
            <MDBox lineHeight={1}>
              <MDTypography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Product Category
              </MDTypography>
              <MDTypography variant="h6" color="white" fontWeight="medium">
                {pcategory}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end" width="25%">
              <MDBox component="img" src={"http://images.tuyaus.com/" + iconImage} alt="IoT Sensor Icon" borderRadius="10px" width="60%" mt={1} />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of MasterCard
IOTManufacturer.defaultProps = {
  color: "dark",
};

// Typechecking props for the MasterCard
IOTManufacturer.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "veoracolor", "veoracolor2", "washroomcolor", "error", "dark"]),
  number: PropTypes.number.isRequired,
  holder: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
};

export default IOTManufacturer;
