import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import styles from "./profileInfoEditModal.module.css";

// for Form
import { TextField, InputAdornment, Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

// API URL
import { veoraconfig } from "widgets/config";

import BeatLoader from "react-spinners/BeatLoader";

const ProfilePasswordEditModal = (props) => {
  const [passwordChangeFormData, setPasswordChangeFormData] = useState({
    email: "",
    authCode: "",
    password: "",
    confirmPassword: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerificationCodeLoading, setIsVerificationCodeLoading] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  useEffect(() => {
    setPasswordChangeFormData({
      email: "",
      authCode: "",
      password: "",
      confirmPassword: "",
    });
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(false);
  }, [props.show]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setPasswordChangeFormData({
      ...passwordChangeFormData,
      [name]: value,
    });
  };
  // const handleFormDataSubmit = async (e) => {
  //   e.preventDefault();
  //   setErrorMsg("");
  //   setSuccessMsg("");
  //   if (
  //     passwordChangeFormData.password !== passwordChangeFormData.confirmPassword
  //   ) {
  //     setErrorMsg("Password does not match!");
  //     return;
  //   }
  //   setIsSubmitting(true);
  //   try {
  //     const response = await axios({
  //       method: "put",
  //       url:
  //         apiConfig.baseURL +
  //         apiConfig.auth.passwordReset +
  //         `?email=${props.email}&authCode=${passwordChangeFormData.authCode}&password=${passwordChangeFormData.password}`,
  //       // data: {
  //       //   email: regiFormData,
  //       // },
  //     });
  //     console.log("email sent code api res", response);
  //     const res = response.data;
  //     if (res.success === true) {
  //       setErrorMsg("");
  //       setSuccessMsg("Password Reset Successful!");
  //     } else {
  //       setSuccessMsg("");
  //       setErrorMsg("Could not reset password! Something Went Wrong!");
  //     }
  //   } catch (error) {
  //     console.log("error in catch", error);
  //     setSuccessMsg("");

  //     setErrorMsg("Could not reset password! Something Went Wrong!");
  //   }
  //   setIsSubmitting(false);
  // };

  return (
    <Modal
      {...props}
      //   className={styles["addCleanerModal"]}
      //   size="sm"
      //   dialogClassName="modal-90w"
      dialogClassName={styles["deleteUserModal"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
          Edit Profile Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles["deleteUserModal-body"]}>
        <FormControl
          className={styles["userPassEditModal-control"]}
          component="form"
          // onSubmit={handleFormDataSubmit}
          fullWidth
        >
          {successMsg.emailCodeSuccessMsg && (
            <Typography variant="h7" color="green" align="center">
              {successMsg.emailCodeSuccessMsg}
            </Typography>
          )}
          {errorMsg.emailCodeErrorMsg && (
            <Typography variant="h7" color="red" align="center">
              {errorMsg.emailCodeErrorMsg}
            </Typography>
          )}
          <TextField
            fullWidth
            // type="password"
            required
            label="Info 1"
            id="authCode"
            name="authCode"
            value={passwordChangeFormData.authCode}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            required
            label="Info 2"
            id="authCode"
            name="authCode"
            value={passwordChangeFormData.authCode}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            required
            label="Info 3"
            id="authCode"
            name="authCode"
            value={passwordChangeFormData.authCode}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            required
            label="Info 4"
            id="authCode"
            name="authCode"
            value={passwordChangeFormData.authCode}
            onChange={handleFormDataChange}
          />

          {/* <FormControlLabel
            required
            control={<Checkbox />}
            label="I have read and agree to the terms"
          /> */}
          {errorMsg && (
            <Typography variant="h7" color="red">
              {errorMsg}
            </Typography>
          )}
          {successMsg && (
            <Typography variant="h7" color="green">
              {successMsg}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            style={{ color: "white" }}
          >
            {isSubmitting === true ? "Please Wait" : "Save changes"}
          </Button>
        </FormControl>
      </Modal.Body>
      <Modal.Footer
        style={{ display: "flex", justifyContent: "center", color: "black" }}
      ></Modal.Footer>
    </Modal>
  );
};

export default ProfilePasswordEditModal;
