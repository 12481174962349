// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Device page components
import Readings from "layouts/device/components/Invoice";

function IOTData(props) {
  console.log("IOTDATA_PROPS", props.statusData);
  //  const firstCode = props.onecode
  //  const firstValue = props.onevalue
  //  const secondCode = props.twocode
  //  const secondValue = props.twovalue
  //  const thirdCode = props.threecode
  //  const thirdValue = props.threevalue
  //  const  fourthCode = props.fourcode
  //  const fourthValue = props.fourvalue
  //  const fifthCode = props.fivecode
  //  const fifthValue = props.fivevalue

  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDTypography variant="h6" fontWeight="medium">
          Current Readings
        </MDTypography>
        <MDButton variant="outlined" color="info" size="small">
          view historic
        </MDButton>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {props?.statusData?.map((status) =>
                <Readings
                  date={status.code}
                  // hour="First data received"
                  reading={status.value.toString()}
                />
            )
          }
          {/* <Readings date={firstCode} hour="First data received" reading={firstValue} />
          <Readings date={secondCode} hour="Second data received" reading={secondValue} />
          <Readings date={thirdCode} hour="Third data received" reading={thirdValue} />
          <Readings date={fourthCode} hour="Fourth data received" reading={fourthValue} />
          <Readings date={fifthCode} hour="Fifth data received" reading={fifthValue} /> */}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default IOTData;
