/* Configuration file for Veora Washrooms

coded by Juan Rivera

*/

// Configuration

export const veoraconfig = {
  veoraclient: {
    clientName: "Thompson",
  },
  veoraapi: {
    url: "https://veoratest.azurewebsites.net/api/v1/",
    // url: "https://veora-dev-server.azurewebsites.net/api/v1/",
    iotdevices:
      "eb4f7839b63063bdec3ikc,eb7804cf6dde116e33hvhe,eb645acaa1efb456877nss,ebc32ad422bc5dc3eecapa,45102754e09806133d2d,eb84a574ecfa372e6ccapr,eba84bac5cf64f9adcfbzf,45102754e09806133d4b,ebdc18eb6c3f9b0607z9ap,ebb4d7a698fa93070b3dfz,eb7c72ba426f92b9a1pb81,ebf5bb435e688e96e8mt5z,eb96f0cf5384c34cd9veem,ebc7f9a288d3acf282vqxn,eb9f365960dcc12e3ajnuy,eb7d539881dd07299fsckm,eb77fa554fbdbed47dkubk,ebbaa8d217947ff4d1fk3w,eb08eb3681942c25b0tfpg,eb458f73adadf67170uxdv",
    clientLogo: "/assets/images/abclogo.png",
    trafficapi: "traffic/getlivetraffic",
    peakhourapi: "peakhour",
    expectedpeakhourapi: "expectedpeakhour",
    notificationsapi: "alert/getalert",
    notificationsApiWithPageNum: "alert/getalert?page=",
    cleanersapi: "worker/getworker",
    // constructionworkerapi: "constructionworker",
    constructionworkerapi: "worker/getworker",
    createWorker: "worker/createworker",
    phonenumberupdateapi: "configurephonenumber",
    batchdataapi: "devicebatchdata/",
    deviceapi: "device/alldeviceinfo",
    historicdata: "device/devicehistoricdata",
    deviceList: "device/deviceidlist",
    sensorCount: "device/sensorcount",
    busiestDay: "traffic/busiestday",
  },
  auth: {
    login: "auth/login",
    tokenVerify: "auth/tokenverify",
    forgotpassword: "auth/forgotpassword",
    verifyPasswordResetToken: "auth/verifyresettoken?token=",
    resetPassword: "auth/reset_password",
    userRegister: "auth/register_user",
    userList: "auth/userlist",
    editUser: "auth/edituser",
    deleteUser: "auth/deleteuser",
  },
  testDevice: {
    deviceIdTest1: "eb7804cf6dde116e33hvhe",
    deviceIdTest2: "ebc7f9a288d3acf282vqxn", // Electric load random values
    deviceIdTest3: "eb96f0cf5384c34cd9veem", // Electric load random values
  },
  veoraalerts: {
    smsEnable: false,
    smsEnableSecurity: false,
    smsEnablePIR: false,
    smsCoolDownTime: 60000,
    notificationsEnablePIR: false,
    notificationsEnableAIR: false,
    notificationsCoolDownTime: 60000,
    veoraVisitorsAlert: 75,
  },
  iotDevicesConfigUpdateTime: {
    globalUpdateTime: 15000,
    pendingActionsUpdateTime: 5000,
    securityUpdateTime: 15000,
    airUpdateTime: 90000,
    lightingUpdateTime: 30000,
    servicesUpdateTime: 60000,
    peopleCountingUpdateTime: 15000,
    buildingLoadUpdateTime: 60000,
    insightsAirUpdateTime: 800000,
    insightsLightUpdateTime: 800000,
    insightsSecurityUpdateTime: 800000,
    insightsServicesUpdateTime: 800000,
    globalNotificationsRefreshTime: 15000,
    washroomDashboardElectricityUpdateTime: 15000,
    ioteb7d539881dd07299fsckm: 55000,
    ioteb77fa554fbdbed47dkubk: 55000,
    iotebbaa8d217947ff4d1fk3w: 55000,
    ioteb08eb3681942c25b0tfpg: 55000,
    ioteb4f7839b63063bdec3ikc: 55000,
    iotebe55d36879c7fd71emtf0: 55000,
    ioteb645acaa1efb456877nss: 55000,
    iotebc32ad422bc5dc3eecapa: 55000,
    iotebf5bb435e688e96e8mt5z: 55000,
    iot45102754e09806133d2d: 55000,
    ioteb7c72ba426f92b9a1pb81: 55000,
    ioteb9f365960dcc12e3ajnuy: 55000,
    ioteb84a574ecfa372e6ccapr: 55000,
    ioteb458f73adadf67170uxdv: 55000,
    iotebc7f9a288d3acf282vqxn: 55000,
    ioteb96f0cf5384c34cd9veem: 55000,
  },
  iotDevicesConfigEnable: {
    globalEnableByDefault: true,
  },
  veoraImages: {
    headerNotifications: "/assets/images/bg-background-notifications-veora.jpg",
    headerConsole: "/assets/images/bg-background-console-veora.jpg",
    headerInsights: "/assets/images/bg-background-configuration-veora.jpg",
    headerProfile: "/assets/images/bg-profile.jpeg",
  },
  cleaning: {
    cleaningBuffer: {
      airQuality: 0.03,
      consummablesLevel: 40,
      electricLoad: 0.75,
      garbageLevels: 75,
      leakData: true,
      trafficData: 30,
    },
    cleaningMeasures: {
      airQuality: "ppm",
      consummablesLevel: "%",
      garbageLevel: "%",
    },
    cleaningIcons: {
      alertIcon: "❗",
      warningIcon: "⚠️",
      okIcon: "🆗",
      goodIcon: "✅",
    },
  },
};

export const vccConfig = {
  vccAPI: {
    baseURL: "https://ql83c6bf3c.execute-api.ca-central-1.amazonaws.com/api/v1",
    auth: {
      login: "/user/login",
      register: "/user/register",
      resetPassword: "/user/resetpasswordmail",
      resetPasswordFirstTime: "/user/resetpassword",
      forgetPassword: "/user/forgetpassword",
    },
    supportTicket: {
      supportTicket: "/supportticket",
    },
    user: {
      users: "/users",
      user: "/user",
    },
    dashboard: {
      fetchDashboard: "/dashboard?limit=10",
      fetchSearchedDashboardData: "/searchtransactionbyid",
    },
    settings: {
      fetchNetsuiteEndPoint: "/settings/netsuitehost",
      updateNetsuiteEndPoint: "/settings/netsuitehost",
    },
  },
};
