/**
=========================================================
========== Omor's coding for login system v1.0
=========================================================
*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// react-loader-spinner
import { ThreeDots } from "react-loader-spinner";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/vcc1.jpg";
import { useParams } from "react-router-dom";
// API URL
import { vccConfig } from "widgets/config";
import { useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
//password visible
import { InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
// for hashing
import bcrypt from "bcryptjs";
const salt = bcrypt.genSaltSync(10);

function Basic({ history }) {
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setpassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isError, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSigninLoading, setIsSigninLoading] = useState(false);
  const [forgetPasswordModalShow, setForgetPasswrodModalShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [authValue, setAuthValue] = useState("");

  // password show starts
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  const handleMouseDownConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  // password show ends

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  let navigate = useNavigate();
  const location = useLocation();
  //  const routeParams = useParams()
  const [searchParams, setSearchParams] = useSearchParams();
  // const authData = searchParams.get("auth");
  useEffect(() => {
    // isVarified();
    // console.log(first)
    const queryParams = new URLSearchParams(location.search);
    const authParam = queryParams.get("auth");
    console.log("auth value:", authParam);
    setAuthValue(authParam);
  }, [location.search]);

  const loginHandler = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");

    console.log("this is the requ body", authValue, email, password);
    const hashedPassword = bcrypt.hashSync(password, salt);

    if (password !== confirmPassword) {
      setErrorMsg("Password does not match!");
      return;
    }
    try {
      setIsSigninLoading(true);
      const res = await fetch(
        // "https://veora-dev-server.azurewebsites.net/api/v1/auth/login",
        vccConfig.vccAPI.baseURL + vccConfig.vccAPI.auth.resetPasswordFirstTime,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${authValue}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            new_password: hashedPassword,
          }),
        }
      );
      const data = await res.json();
      console.log('password reset response', data)
      if (data.success === true) {
        setError(false);
        setErrorMsg("");
        setSuccessMsg(
          "Password Reset Successful! Please login with new password"
        );
        // localStorage.setItem("authInfo", JSON.stringify(data));
         
        navigate("/login", { state: { successMsg: "Password Set Successfully. Please login using your new credentials" } });
        setIsSigninLoading(false);

        console.log(data);
      } else {
        console.log("could not login");
        setErrorMsg("Could not reset password! Please try again later!");
        setSuccessMsg("");
        setError(true);
        setIsSigninLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError(true);
      setErrorMsg("Could not reset password! Please try again later!");
      setSuccessMsg("");
      setIsSigninLoading(false);
    }
  };

  const handleForgetPassword = () => {
    // console.log("ok");
    setForgetPasswrodModalShow(true);
  };

  return (
    <>
      {isLoading === false ? (
        <>
          <BasicLayout image={bgImage}>
            <Card>
              <MDBox
               style={{ background: "#2E4082" }}
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign="center"
              >
                <MDTypography
                  variant="h4"
                  fontWeight="medium"
                  color="white"
                  mt={1}
                >
                  Change Password
                </MDTypography>
                
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" onSubmit={loginHandler} role="form">
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      required
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    //  disabled
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type={showPassword ? "text" : "password"}
                      label="New Password"
                      required
                      fullWidth
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type={showConfirmPassword ? "text" : "password"}
                      label="Confirm Password"
                      required
                      fullWidth
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle Confirm password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </MDBox>
                  {/* <MDBox
                    display="flex"
                    alignItems="center"
                    ml={-1}
                    // justifyContent="space-between"
                    justifyContent="end"
                  >
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      onClick={handleForgetPassword}
                      sx={{ cursor: "pointer" }}
                    >
                      &nbsp;&nbsp;Forget password?
                    </MDTypography>
                  </MDBox> */}
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {errorMsg.length > 0 ? (
                      <div style={{ color: "red" }}>{errorMsg}</div>
                    ) : null}
                    {successMsg.length > 0 ? (
                      <div style={{ color: "green" }}>{successMsg}</div>
                    ) : null}
                  </div>
                  {successMsg.length > 0 ? (
                    <MDBox mt={3} mb={1} textAlign="center">
                      {/* <MDTypography variant="button" color="text"> */}
                      <MDButton
                        variant="contained"
                        // color="veoracolor2"
                        // style={{ background: "#FF0064" }}
                      >
                        <MDTypography
                          component={Link}
                          to="/login"
                          variant="button"
                          color="red"
                          fontWeight="medium"
                        >
                          <span style={{ color: "red" }}>Go to Login Page</span>
                        </MDTypography>
                      </MDButton>
                      {/* </MDTypography> */}
                    </MDBox>
                  ) : (
                    <MDBox mt={3} mb={1}>
                      {isSigninLoading === false ? (
                        <>
                          <MDButton
                            type="submit"
                            color="veoracolor2"
                            fontWeight="light"
                            fullWidth
                            style={{ background: "#2E4082", color:"white" , fontSize: "16px"}}
                          >
                            Submit
                          </MDButton>
                        </>
                      ) : (
                        <>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            //color="veoracolor2"
                            fullWidth
                            disabled
                            style={{ background: "#5494e8" }}
                          >
                            <ThreeDots
                              height="25"
                              width="25"
                              radius="9"
                              color="veoracolor2"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </MDButton>
                        </>
                      )}
                    </MDBox>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </BasicLayout>
        </>
      ) : null}
    </>
  );
}

export default Basic;
