/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================
*/

// react-routers components
import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

function ProfileInfoCard({
  title,
  description,
  info,
  action,
  editBtnAction,
  shadow,
}) {
  const labels = [];
  const values = [];
  const { socialMediaColors } = colors;
  const { size } = typography;

  // Convert this form `objectKey` of the object key in to this `object key`
  Object.keys(info).forEach((el) => {
    if (el.match(/[A-Z\s]+/)) {
      const uppercaseLetter = Array.from(el).find((i) => i.match(/[A-Z]+/));
      const newElement = el.replace(
        uppercaseLetter,
        ` ${uppercaseLetter.toLowerCase()}`
      );

      labels.push(newElement);
    } else {
      labels.push(el);
    }
  });

  // Push the object values into the values array
  Object.values(info).forEach((el) => values.push(el));

  // Render the card info items
  const renderItems = labels.map((label, key) => (
    <MDBox key={label} display="flex" py={1} pr={2}>
      <MDTypography
        variant="button"
        fontWeight="bold"
        textTransform="capitalize"
      >
        {label}: &nbsp;
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" color="text">
        &nbsp;{values[key]}
      </MDTypography>
    </MDBox>
  ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none", width: "30rem" }}>
      <MDBox
        display="flex"
        justifyContent="start"
        // justifyContent="space-between"
        alignItems="center"

        // pt={2}
        // px={2}
      >
        <Typography variant="h4" color="initial" align="center" >
          Contact Us
        </Typography>
      </MDBox>
      <MDBox p={2}>
        {/* <MDBox mb={2} lineHeight={1} display="flex" justifyContent={"start"}>
          <MDTypography variant="button" color="text" fontWeight="light">
            Contact US
          </MDTypography>
        </MDBox> */}
        <MDBox opacity={0.3}>
          <Divider />
        </MDBox>
        {/* Support Info Starts  */}
        <MDBox>
          <MDBox display="flex"  justifyContent={"start"}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Phone &nbsp;:
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp; +1 254 455 5666
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox >
          <MDBox display="flex" py={1} pr={2} justifyContent={"start"}>
            <MDTypography
              variant="button"
              fontWeight="bold"
              textTransform="capitalize"
            >
              Email &nbsp;:
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp; support-prs@vcc.com
            </MDTypography>
          </MDBox>
        </MDBox>

        {/* Support Info Ends  */}
        {/* <MDBox>{renderItems}</MDBox> */}
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfileInfoCard
ProfileInfoCard.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfileInfoCard
ProfileInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  info: PropTypes.objectOf(PropTypes.string).isRequired,
  social: PropTypes.arrayOf(PropTypes.object).isRequired,
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
  shadow: PropTypes.bool,
};

export default ProfileInfoCard;
