import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import styles from "./profileInfoEditModal.module.css";

// for Form
import { TextField, InputAdornment, Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl, Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

// API URL
import { vccConfig } from "widgets/config";
import axios from "axios";

import BeatLoader from "react-spinners/BeatLoader";

const ProfilePasswordEditModal = (props) => {
  const [editUserFormData, setEditUserFormData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerificationCodeLoading, setIsVerificationCodeLoading] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);

  useEffect(() => {
    setEditUserFormData(props.profileInfo ? props.profileInfo : {});
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(false);
  }, [props.show]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;
    setEditUserFormData({
      ...editUserFormData,
      [name]: value,
    });
  };
  const handleFormDataSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(true);
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));

    try {
      const response = await axios({
        method: "patch",
        url: vccConfig.vccAPI.baseURL + vccConfig.vccAPI.user.user,
        headers: {
          Authorization: `Bearer ${authData.data.token}`,
          "Content-Type": "application/json",
        },
        data: {
          first_name: editUserFormData.first_name,
          last_name: editUserFormData.last_name,
          // email: editUserFormData.email,
        },
      });

      const res = response.data;
      if (res.success === true) {
        setErrorMsg("");
        setSuccessMsg("User modification successful!");
        props.setFetchAgainFlag((prev) => prev + 1);
      } else {
        setSuccessMsg("");
        setErrorMsg("Could not update user information! Something Went Wrong!");
      }
    } catch (error) {
      console.log("error in catch", error);
      setSuccessMsg("");

      setErrorMsg("Could not update user information! Something Went Wrong!");
    }
    setIsSubmitting(false);
  };

  return (
    <Modal
      {...props}
      //   className={styles["addCleanerModal"]}
      //   size="sm"
      //   dialogClassName="modal-90w"
      dialogClassName={styles["deleteUserModal"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
          Edit Profile Information
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles["deleteUserModal-body"]}>
        <FormControl
          className={styles["userPassEditModal-control"]}
          component="form"
          onSubmit={handleFormDataSubmit}
          fullWidth
        >
          {successMsg.emailCodeSuccessMsg && (
            <Typography variant="h7" color="green" align="center">
              {successMsg.emailCodeSuccessMsg}
            </Typography>
          )}
          {errorMsg.emailCodeErrorMsg && (
            <Typography variant="h7" color="red" align="center">
              {errorMsg.emailCodeErrorMsg}
            </Typography>
          )}
          <TextField
            fullWidth
            // type="password"
            required
            label="First Name"
            id="first_name"
            name="first_name"
            value={editUserFormData.first_name}
            onChange={handleFormDataChange}
          />
          <TextField
            fullWidth
            // type="password"
            required
            label="Last Name"
            id="last_name"
            name="last_name"
            value={editUserFormData.last_name}
            onChange={handleFormDataChange}
          />
          {/* <TextField
            fullWidth
            // type="password"
            required
            label="Email"
            id="email"
            name="email"
            value={editUserFormData.email}
            onChange={handleFormDataChange}
          /> */}
          {/* <TextField
            fullWidth
            // type="password"
            required
            label="Info 4"
            id="authCode"
            name="authCode"
            value={passwordChangeFormData.authCode}
            onChange={handleFormDataChange}
          /> */}

          {/* <FormControlLabel
            required
            control={<Checkbox />}
            label="I have read and agree to the terms"
          /> */}
          {errorMsg && (
            <Typography variant="h7" color="red">
              {errorMsg}
            </Typography>
          )}
          {successMsg && (
            <Typography variant="h7" color="green">
              {successMsg}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={isSubmitting}
            style={{ backgroundColor: "#2E4082" , color:"white"}}
          >
            {isSubmitting === true ? "Please Wait" : "Save changes"}
          </Button>
        </FormControl>
      </Modal.Body>
      <Modal.Footer
        style={{ display: "flex", justifyContent: "center", color: "black" }}
      ></Modal.Footer>
    </Modal>
  );
};

export default ProfilePasswordEditModal;
