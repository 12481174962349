import React, { useEffect, useState } from "react";
import { Button,Typography } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import styles from "./forgetPasswordModal.module.css";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { vccConfig } from "widgets/config";

// API URL
import { veoraconfig } from "widgets/config";

import BeatLoader from "react-spinners/BeatLoader";

const ForgetPasswordModal = (props) => {
  const [passwordChangeFormData, setPasswordChangeFormData] = useState({
    email: "",
    authCode: "",
    password: "",
    confirmPassword: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");

  const [isVerificationCodeLoading, setIsVerificationCodeLoading] =
    useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setPasswordChangeFormData({
      email: "",
      authCode: "",
      password: "",
      confirmPassword: "",
    });
    setErrorMsg("");
    setSuccessMsg("");
    setIsSubmitting(false);
  }, [props.show]);

  const handleResetPassword = async (e) => {
    // Send jsonData to the API endpoint
    e.preventDefault();
    try {
      setIsSubmitting(true);
      
      const res = await fetch(
        vccConfig.vccAPI.baseURL + vccConfig.vccAPI.auth.forgetPassword,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: email
          }),
        }
      );
      
      const response = await res.json();
      console.log("pass reset api res", response);
      if (response.success === true) {
        console.log("Success:", response.data);
        setErrorMsg("");
        //
        setSuccessMsg("Password Reset Successful! Please Check Your Email");

      } else {
        console.error("Error after response:", response.data);
        setErrorMsg("Something Went Wrong! Please Try Again Later");
      setIsError(true);

        setSuccessMsg("");
      }
    } catch (error) {
     
      console.error("Error:", error);
      setErrorMsg("Something Went Wrong! Please Try Again Later");
      setIsError(true);
      setSuccessMsg("");
    }
    //console.log(email)
    setIsSubmitting(false);
  };
  
  return (
    <Modal
      {...props}
      dialogClassName={styles["deleteUserModal"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
    >
      <Modal.Header className="px-4" closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
          Reset Password
        </Modal.Title>
      </Modal.Header>
  
      <MDBox component="form" onSubmit={handleResetPassword} role="form">
        <Modal.Body className={styles["deleteUserModal-body"]}>
          <MDBox mb={3}>
            <>
              <Typography variant="h8" color="initial" fontWeight={"normal"}>
                Note: A reset password link will be sent to your email.
              </Typography>
            </>
            <MDInput
              type="email"
              required
              label="Email"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </MDBox>
          {isError === true ? (
                    <>
                      <MDBox mt={2} mb={1} textAlign="center">
                        <MDTypography
                          color="error"
                          fontWeight="light"
                          // verticalAlign="middle"
                          variant="h6"
                        >
                          {errorMsg}
                        </MDTypography>
                      </MDBox>
                    </>
                  ) : null}
        </Modal.Body>
  
        <Modal.Footer
          style={{ display: "flex", justifyContent: "center", color: "black" }}
        >
          {isSubmitting === false ? (
            <>
            
              <Button
                variant="contained"
                type="submit"
                style={{
                  width: "20rem",
                  backgroundColor: "#2E4082",
                  color: "white",
                  borderRadius: "10px",
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "#ffff",
                    borderColor: "#ff0062",
                    boxShadow: "none",
                  },
                }}
              >
                <span>Send Password Reset Email</span>
              </Button>
            
            </>
          ) : (
            <Button
              variant="contained"
              disabled
              style={{ width: "20rem" }}
              sx={{
                background: "#BE2528",
                color: "white",
                "&:hover": {
                  backgroundColor: "#CB3235",
                  borderColor: "#0062cc",
                  boxShadow: "none",
                },
              }}
            >
              <BeatLoader color="#BE2528" />
            </Button>
          )}
        </Modal.Footer>
      </MDBox>
    </Modal>
  );
  
};

export default ForgetPasswordModal;
