import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import styles from "./addNewRuleModal.module.css";

// for Form
import { TextField, InputAdornment } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import FormControlLabel from "@mui/material/FormControlLabel";
import { FormControl } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
// API URL
import { veoraconfig } from "widgets/config";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddNewRuleModal = (props) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [showSaveBtn, setShowSaveBtn] = useState(false);
  const [allRoles, setAllRoles] = useState([]);
  // const [upperLimit, setUpperLimit] = useState();
  // const [lowerLimit, setLowerLimit] = useState();
  // const [actionNeeded, setActionNeeded] = useState();
  const [formData, setFormData] = useState({
    ruleName: "",
    upperLimit: "",
    lowerLimit: "",
    actionNeeded: "",
  });

  useEffect(() => {
    setFormData({
      ruleName: "",
      upperLimit: "",
      lowerLimit: "",
      actionNeeded: "",
    });
  }, [props]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddNewRuleSubmit = (e) => {
    e.preventDefault();

    console.log(formData);
  };

  return (
    <Modal
      {...props}
      //   className={styles["addCleanerModal"]}
      //   size="sm"
      //   dialogClassName="modal-90w"
      // dialogClassName={styles["addUserModal"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard="false"
    >
      <FormControl
        // className={styles["addUserModalForm-control"]}
        component="form"
        error={true}
        onSubmit={handleAddNewRuleSubmit}
        fullWidth
        style={{
          overflow: "hidden",
          paddingTop: "1rem",
          paddingBottom: "2rem",
        }}
      >
        <Modal.Header className="px-4" closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">
            Add New Rule
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles["addNewRuleForm-control"]}>
            <TextField
              autoFocus
              required
              size="normal"
              id="ruleName"
              name="ruleName"
              label="Rule Name"
              value={formData.ruleName}
              onChange={handleChange}
            />
            <TextField
              autoFocus
              required
              size="normal"
              id="upperLimit"
              name="upperLimit"
              label="Upper Limit"
              value={formData.upperLimit}
              onChange={handleChange}
            />
            <TextField
              required
              size="normal"
              id="lowerLimit"
              name="lowerLimit"
              label="Lower Limit"
              value={formData.lowerLimit}
              onChange={handleChange}
            />
            <TextField
              required
              size="normal"
              id="actionNeeded"
              name="actionNeeded"
              label="Required Action"
              value={formData.actionNeeded}
              onChange={handleChange}
            />
            {/* </FormControl> */}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ display: "flex", justifyContent: "center", color: "black" }}
        >
          <Button
            type="submit"
            variant="contained"
            // onClick={handleAddUserFormSubmit}
            style={{ width: "20rem" }}
            // disabled={!showSaveBtn}
          >
            <div style={{ color: "white" }}>Save</div>
          </Button>
        </Modal.Footer>
      </FormControl>
    </Modal>
  );
};

export default AddNewRuleModal;
