import React, { useEffect, useState } from "react";
import DataTable from "./DataTable";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import { Button } from "@mui/material";
// API URL
import { vccConfig } from "widgets/config";

// Edit User Modal
import EditUserModal from "../EditUserModal/EditUserModal";

// Delete User Modal
import DeleteUserModal from "../DeleteUserModal/DeleteUserModal";
// reset passwrod
import ResetPasswordModal from "../ResetPasswordModal/ResetPasswordModal";
const UserlistTable = (props) => {
  const [userData, setUserData] = useState([]);
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
  const [resetPasswordUserModalOpen, setResetPasswordUserModalOpen] =
    useState(false);
  const [selectedUserData, setSelectedUserData] = useState([]);
  const [fetchAgainFlag, setFetchAgainFlag] = useState(0)
  function handleEditUser(id) {
    console.log("user data to edit", id);
    setSelectedUserData(id);
    setEditUserModalOpen(true);
  }
  function handleDeleteUser(id) {
    console.log("user data to delete", id);
    setSelectedUserData(id);
    setDeleteUserModalOpen(true);
  }
  function handleResetPassword(id) {
    console.log("user data to reset pass", id);
    setSelectedUserData(id);
    setResetPasswordUserModalOpen(true);
  }
  useEffect(() => {
    // console.log('selected User data', selectedUserData[4].value)
  }, [selectedUserData]);
  const columns = [
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    // {
    //   Header: "Username",
    //   accessor: "username",
    // },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Merchant ID",
      accessor: "merchant_id",
    },
  ];

  const fetchUserData = async () => {
    const authData = JSON.parse(localStorage.getItem("vccAuthInfo"));

    try {
      const response = await fetch(
        vccConfig.vccAPI.baseURL + vccConfig.vccAPI.user.users,
        {
          headers: {
            Authorization: `Bearer ${authData.data.token}`,
            "Content-Type": "application/json",
          },
        }
      ); // Replace with your API endpoint
      const data = await response.json();
      console.log("user api res", data);
      if (data.success === true) {
        console.log("user data", data?.data?.users);
        setUserData(data?.data?.users);
      } else {
        console.log("success: false");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    setUserData([]);
    fetchUserData();
  }, [fetchAgainFlag, props.renderFlag]);

  // const userData = [
  //   {
  //     _id: "5631068f41a243d2bafc387483c3aed1",
  //     clientid: "a57b352d6b284ad7a00a080487cc15b0",
  //     contact: "+54622",
  //     email: "user1@gmail.com",
  //     isActive: true,
  //     isPasswordSet: false,
  //     name: "user1",
  //     pageaccess: ["dashboard", "console", "insights", "washroom"],
  //     password:
  //       "pbkdf2:sha256:260000$HtTzc156lNNWNKci$717bbe9f5c8f25216041014c0c7be8b824c0f68e0a326f7c20a7baef30b703c0",
  //     role: ["washroommanager", "buildingmanager"],
  //   },
  // ];

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        id: "Action",
        Header: "Action",
        align: "center",
        Cell: ({ row }) => (
          <>
            {/* <Button
              variant="contained"
              size="small"
              sx={{
                marginLeft: "5px",
                background: "#171946",
                color: "white",
                "&:hover": {
                  backgroundColor: "#000000",

                  borderColor: "#171946",
                  boxShadow: "none",
                },
              }}
              // disabled
              onClick={() => {
                handleEditUser(row.cells);
              }}
            >
              <span style={{ color: "#ffffff" }}>Edit</span>
            </Button> */}
            <Button
              variant="contained"
              // color="red"
              size="small"
              sx={{
                marginLeft: "5px",
                background: "#CB3235",
                color: "white",
                "&:hover": {
                  backgroundColor: "#000000",
                  borderColor: "#ff0062",
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                handleDeleteUser(row.cells);
              }}
            >
              <span style={{ color: "white" }}>Delete</span>
            </Button>
            <Button
              variant="contained"
              // color="red"
              size="small"
              sx={{
                marginLeft: "5px",
                background: "#2E4082",
                color: "white",
                "&:hover": {
                  backgroundColor: "#000000",
                  borderColor: "#ff0062",
                  boxShadow: "none",
                },
              }}
              onClick={() => {
                handleResetPassword(row.cells);
              }}
            >
              <span style={{ color: "white" }}>reset password</span>
            </Button>
          </>
        ),
      },
    ]);
  };
  return (
    <div>
      <EditUserModal
        onHide={() => setEditUserModalOpen(false)}
        // clientPermittedPagesList={clientPermittedPagesList}
        selectedUserData={selectedUserData}
        clientPermittedPagesList={props.clientPermittedPagesList}
        show={editUserModalOpen}
      />
      <DeleteUserModal
        onHide={() => setDeleteUserModalOpen(false)}
        selectedUserData={selectedUserData}
        show={deleteUserModalOpen}
        setFetchAgainFlag={setFetchAgainFlag}
      />
      <ResetPasswordModal
        onHide={() => setResetPasswordUserModalOpen(false)}
        selectedUserData={selectedUserData}
        show={resetPasswordUserModalOpen}
        setFetchAgainFlag={setFetchAgainFlag}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={4}
                mt={-5}
                py={3}
                px={2}
               // variant="gradient"
                bgColor="#6B7CA8"
                borderRadius="lg"
                coloredShadow="light"
              >
                <MDTypography
                  variant="h5"
                  color="white"
                  fontWeight="light"
                  align="center"
                >
                  Current Users
                </MDTypography>
              </MDBox>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                borderRadius="lg"
                coloredShadow="veoracolor"
              >
                {/* Here, instead of MaterialTable, we used MUI Table  */}
                <DataTable
                  canSearch={true}
                  column={columns}
                  tabledata={userData}
                  useHook={tableHooks}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
};

export default UserlistTable;
